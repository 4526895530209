/* @flow */
import React from 'react';
import { observer } from 'mobx-react';
import AnimatedTextStyle from './AnimatedText.style';
import classNames from 'classnames/bind';
const cn = classNames.bind(AnimatedTextStyle);

opaque type Props = {
    text: string;
    color: string;
    fadeOut: Boolean;
}

class AnimatedText extends React.Component<Props> {
    static defaultProps = {
        fadeOut: false,
    }
    render() {
        return (
            <AnimatedTextStyle>
                <div className={cn('wrapper')} style={{color: this.props.color}}>
                    {this.props.text.split('\n').map((line, l)=>{
                        return <div key={l+line} className={cn('line')}>
                            {line.split(' ').map((word, w)=>{
                                return <div key={w+word} className={cn('word', {fadeOut: this.props.fadeOut})}>
                                    {word}
                                </div> // word
                            })}
                        </div> // line
                    })}
                </div>
            </AnimatedTextStyle>
        );
    }
}

export default observer(AnimatedText);

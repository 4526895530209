import styled from 'styled-components';

const Component = styled.div`
  margin-top: 64px; 
  position: relative;
  z-index: -1;
  
  .gallery-caption{
      p{
        @media (max-width: 767px){
            width: 90%;
            margin: 15px auto;
          }
        }
      }
  }
  
  .image-gallery-captions {
    margin-top: 24px;
  }

  .image-gallery-slides {
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
  }

  .image-gallery-thumbnails-container {
    margin-top: 36px;
  }

  .image-gallery-fullscreen-button::before,
  .image-gallery-play-button::before,
  .image-gallery-left-nav::before,
  .image-gallery-right-nav::before {
    color: #F95757;
    text-shadow: none;
  }
  
  // Navigation hover for left and right 
  .image-gallery-fullscreen-button:hover::before,
  .image-gallery-play-button:hover::before,
  .image-gallery-left-nav:hover::before,
  .image-gallery-right-nav:hover::before {
    color: #363636;
  }

  .image-gallery-thumbnail + .image-gallery-thumbnail{
    border: none;
    margin-left: 10px;
  }
  
  .image-gallery-thumbnail.active {
    border: 4px solid #F95757;
    box-sizing: border-box;
  }
  
  
  .image-gallery-left-nav {
    @media (min-width: 1050px){  
      left: -76px;
    }
  }

  .image-gallery-right-nav {
    
    @media (min-width: 1050px){
      right: -76px;
    }
  }

  .image-gallery-right-nav, .image-gallery-left-nav{
    &:focus{ 
      outline: 0;
    }
  }
    
.image-gallery-captions-container{

@media (min-width: 1050px){
      color: #FFFFFF;
      background: rgba(0, 0, 0, 0.54);
      font-weight: normal;
      position: absolute;
      bottom: 154px; // Magic number :C
      width: 100%;
      padding: 15px;

      p {
        margin: 0;
      }
    }
}
`;
export default Component;

export default {
  title: "Credits",
  credits: [
  {
  role: "Writer/Director/Producer",
  names: [
    "Anna Fahr"
  ]},
  {
  role: "Technical Director",
  names: [
   "Evan Jones" 
  ]},
  {
  role: "Lead Designer",
  names: [
  "Amanda Lopez"
  ]},
  {
  role: "Developers",
  names: [
    "Aaron Bernstein",
    "Adrian Miasik",
    "Alex Leitch"
  ]},
  {
  role: "Designer",
  names: [
    "Jeff Flores"
  ]},
  {
  role: "Co-Producer",
  names: [
    "Habib Battah"
  ]},
  {
  role: "Development Producer",
  names: [
    "Victoria Evans"
  ]},
  {
  role: "Associate Producer",
  names: [
    "Sonya Suraci"
  ]},
  {
  role: "Participants",
  names: [
    "Amani Idress",
    "Ibrahim Haswa",
    "Basel Haswa",
    "Taim Haswa",
    "Aeham Haswa",
    "Manal Ammar",
    "Anas Idrees",
    "Walid Idrees",
    "Shahd Idrees",
    "Amjad Idrees"
  ]},
  {
  role: "Director of Photography",
  names: [
    "Elsy Hajjar"
  ]},
  {
  role: "Sound Recordist",
  names: [
    "Anna Fahr & Habib Battah"
  ]},
  {
  role: "Additional Videography and Photography",
  names: [
    "Anna Fahr & Habib Battah"
  ]},
  {
  role: "Editors",
  names: [
    "Rita Monzer & Anna Fahr"
  ]},
  {
  role: "Translators",
  names: [
    "Habib Battah",
    "Yasmeen Mobayed"
  ]},
  {
  role: "Sound Editor & Mixer",
  names: [
    "Jeremy Van Caulart"
  ]},
  {
  role: "Colour Grader",
  names: [
    "Chrystel Elias",
    "Lucid Post"
  ]},
  {
  role: "Composer",
  names: [
    "Alan Spiljak"
  ]},
  {
  role: "Music",
  type: "music",
  names: [
    {
      title: "SOMETHING WONDERFUL",
      album: "Silverlight"
    },
    {
      title: "FORGOTTEN",
      album: "Silverlight"
    },
    {
      title: "SUN",
      album: "Colors"
    },
    {
      title: "NEW DAY",
      album: "Colors"
    },
    {
      title: "EMPTY DAYS",
      album: "Silverlight"
    },
    {
      title: "FLYING AWAY",
      album: "Silverlight"
    },
    {
      title: "TIME",
      album: "Colors"
    },
    {
    title: "LIGHT BLUE",
    album: "Silverlight"},
  ]},
  {
  role: "Logo Concept and Design",
  names: [
    "Flora Fahr"
  ]},
  {
  role: "Special Thanks",
  names: [
    "Imani Idress",
    "Manal Ammar",
    "The Haswa family",
    "The Idrees family",
    "Suzanne Lacey",
    "Rachel Feldbloom-Wood",
    "Rita Matta",
    "Mai Abbas",
    "George Azar",
    "Lucid Post",
  ]}]};


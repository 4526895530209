import amani_thumb0 from '../../images/gallery/amani/thumbnails/Amani_bw-no-text.png';
import amani_thumb1 from '../../images/gallery/amani/thumbnails/1-amani-kids-drawing.png';
import amani_thumb2 from '../../images/gallery/amani/thumbnails/2-amani-house-drawing.png';
import amani_thumb3 from '../../images/gallery/amani/thumbnails/3-amani-taim-drawing.png';
import amani_thumb4 from '../../images/gallery/amani/thumbnails/4-amani-interview.png';
import amani_thumb5 from '../../images/gallery/amani/thumbnails/5-amani-cooking.png';
import amani_thumb6 from '../../images/gallery/amani/thumbnails/6-amani-thinking.png';
import amani_thumb7 from '../../images/gallery/amani/thumbnails/7-amani-swing.png';
import amani_thumb8 from '../../images/gallery/amani/thumbnails/8-amani-family-portrait.png';
// import amani_thumb9 from '../../images/gallery/amani/thumbnails/9-amani-kids-playing.png';
import amani_thumb10 from '../../images/gallery/amani/thumbnails/10-amani-taim-aeham.png';
import amani_thumb11 from '../../images/gallery/amani/thumbnails/11-amani-house.png';
import amani_thumb12 from '../../images/gallery/amani/thumbnails/12-amani-games.png';

import amani_image0 from '../../images/gallery/amani/Amani_bw-no-text.jpg';
import amani_image1 from '../../images/gallery/amani/1-amani-kids-drawing.jpg';
import amani_image2 from '../../images/gallery/amani/2-amani-house-drawing.jpg';
import amani_image3 from '../../images/gallery/amani/3-amani-taim-drawing.jpg';
import amani_image4 from '../../images/gallery/amani/4-amani-interview.jpg';
import amani_image5 from '../../images/gallery/amani/5-amani-cooking.jpg';
import amani_image6 from '../../images/gallery/amani/6-amani-thinking.jpg';
import amani_image7 from '../../images/gallery/amani/7-amani-swing.jpg';
import amani_image8 from '../../images/gallery/amani/8-amani-family-portrait.jpg';
// import amani_image9 from '../../images/gallery/amani/9-amani-kids-playing.jpg';
import amani_image10 from '../../images/gallery/amani/10-amani-taim-aeham.jpg';
import amani_image11 from '../../images/gallery/amani/11-amani-house.jpg';
import amani_image12 from '../../images/gallery/amani/12-amani-games.jpg';
import amani_image13 from '../../images/gallery/amani/Amani_w-text.jpg';

import manal_thumb0 from '../../images/gallery/manal/thumbnails/Manal_bw-no-text.jpg';
import manal_thumb1 from '../../images/gallery/manal/thumbnails/1-Manal_hallway.jpg';
import manal_thumb2 from '../../images/gallery/manal/thumbnails/2-Manal_atwork_1.jpg';
import manal_thumb3 from '../../images/gallery/manal/thumbnails/3-boys_hallway.jpg';
import manal_thumb4 from '../../images/gallery/manal/thumbnails/4-girl_classroom.jpg';
import manal_thumb5 from '../../images/gallery/manal/thumbnails/5-kids_schoolyard.jpg';
import manal_thumb6 from '../../images/gallery/manal/thumbnails/6-Manal_incar.jpg';
import manal_thumb7 from '../../images/gallery/manal/thumbnails/7-Drawing_w_kids.jpg';
import manal_thumb8 from '../../images/gallery/manal/thumbnails/8-Doll.jpg';
import manal_thumb9 from '../../images/gallery/manal/thumbnails/9-Manal_portrait_1.jpg';
import manal_thumb10 from '../../images/gallery/manal/thumbnails/10-Manal_Anas_balcony.jpg';
import manal_thumb11 from '../../images/gallery/manal/thumbnails/11-Manal_kids_3.jpg';

import manal_image0 from '../../images/gallery/manal/Manal_bw-no-text.jpg';
import manal_image1 from '../../images/gallery/manal/1-Manal_hallway.jpg';
import manal_image2 from '../../images/gallery/manal/2-Manal_atwork_1.jpg';
import manal_image3 from '../../images/gallery/manal/3-boys_hallway.jpg';
import manal_image4 from '../../images/gallery/manal/4-girl_classroom.jpg';
import manal_image5 from '../../images/gallery/manal/5-kids_schoolyard.jpg';
import manal_image6 from '../../images/gallery/manal/6-Manal_incar.jpg';
import manal_image7 from '../../images/gallery/manal/7-Drawing_w_kids.jpg';
import manal_image8 from '../../images/gallery/manal/8-Doll.jpg';
import manal_image9 from '../../images/gallery/manal/9-Manal_portrait_1.jpg';
import manal_image10 from '../../images/gallery/manal/10-Manal_Anas_balcony.jpg';
import manal_image11 from '../../images/gallery/manal/11-Manal_kids_3.jpg';
import manal_image12 from '../../images/gallery/manal/Manal_w-text.jpg';


export default {
  amani: {
    desktop: {
      original: amani_image13,
      thumbnail: amani_thumb0,
      caption: ""
    },
    mobile: {
      original: amani_image0,
      thumbnail: amani_thumb0,
      caption: "Amani is a married mother of three from Homs who has lived in Lebanon for one year. She and her husband, Ebrahim, lost their home in one of the first bombing campaigns that hit their neighbourhood in 2013 and have been displaced ever since." +
        "\n\n" +
        "She is a university educated high school math teacher who now teaches kindergarten at an NGO based in the Bekaa Valley. Ebrahim, also a high school math teacher, works for various NGOs to help support the family." +
        "\n\n" +
        "While Amani and Ebrahim are at work, their ten-year-old son, Basel, watches over his two younger brothers, four-year-old Aeham and two-year-old Taim. She said she worries about her children all the time but that there is no other choice, they must work." +
        "\n\n" +
        "She went on to say that the hardest thing she has had to deal with is accepting the fact that she is a refugee."
    },
    images: [
      {
        original: amani_image1,
        thumbnail: amani_thumb1,
        caption: `Amani and her children work on drawings for the Migrant Mothers of Syria interactive website.`
      },
      {
        original: amani_image2,
        thumbnail: amani_thumb2,
        caption: `First step is to draw a house.`
      },
      {
        original: amani_image3,
        thumbnail: amani_thumb3,
        caption: `Even two-year-old Taim takes part.`
      },
      {
        original: amani_image4,
        thumbnail: amani_thumb4,
        caption: `Amani opens up about the struggles she and her family have faced over their many years on the move.`
      },
      {
        original: amani_image5,
        thumbnail: amani_thumb5,
        caption: `Amani tries to keep her memories of home alive by cooking traditional dishes from Syria. `
      },
      {
        original: amani_image6,
        thumbnail: amani_thumb6,
        caption: `It’s hard to come to terms with the fact that they may never go home.`
      },
      {
        original: amani_image7,
        thumbnail: amani_thumb7,
        caption: `The swing in the background is one of the few belongings the family brought with them from Syria.`
      },
      {
        original: amani_image8,
        thumbnail: amani_thumb8,
        caption: `The family poses at the entrance of their one-bedroom apartment in Bar Elias, Lebanon.`
      },
      // {
      //   original: amani_image9,
      //   thumbnail: amani_thumb9,
      //   caption: `The children play together on their cul-de-sac street.`
      // },
      {
        original: amani_image10,
        thumbnail: amani_thumb10,
        caption: `Aeham (4) and Taim (2)`
      },
      {
        original: amani_image11,
        thumbnail: amani_thumb11,
        caption: `Their building is mostly occupied by Lebanese tenants who are friendly and supportive of Amani and her family.`
      },
      {
        original: amani_image12,
        thumbnail: amani_thumb12,
        caption: `In the evening, the family plays games together like Rock, Paper, Scissors.`
      }
    ]
  },
  manal: {
    mobile: {
      thumbnail: manal_thumb0,
      original: manal_image0,
      caption: "Manal is a married mother of three from the western Syrian town of al-Qusayr who now resides in Tripoli, a city in north Lebanon. Her family fled Syria after a bomb attack on their home, which injured her son, Walid, who was four years old at the time." +
      "\n\n" +
      "In Tripoli, she helps run a school for Syrian refugee children who don’t have access to formal education in Lebanon. Over one thousand students, ages four to fourteen, attend the school, many of whom have been without education for several years." +
      "\n\n"+
      "Manal is dedicated to ensuring these children and others get the education they deserve and, although she is not legally allowed to work in Lebanon, manages to find ways to continue working towards this goal."
    },
    desktop: {
      original: manal_image12,
      thumbnail: manal_thumb0,
      caption:""
    },
    images: [
      {
        thumbnail: manal_thumb1,
        original: manal_image1,
        caption: `Manal at work.`
      },
      {
        thumbnail: manal_thumb2,
        original: manal_image2,
        caption: `Manal meets with a colleague to discuss the issues of the day.`
      },
      {
        thumbnail: manal_thumb3,
        original: manal_image3,
        caption: `A student catches a glimpse of our film crew as he hurries off to class.`
      },
      {
        thumbnail: manal_thumb4,
        original: manal_image4,
        caption: `Classes tend to be mixed ages and mixed gender.`
      },
      {
        thumbnail: manal_thumb5,
        original: manal_image5,
        caption: `Recess.`
      },
      {
        thumbnail: manal_thumb6,
        original: manal_image6,
        caption: `Manal hurries home after work is over to make dinner for her family.`
      },
      {
        thumbnail: manal_thumb7,
        original: manal_image7,
        caption: `Manal’s children prepare drawings for the Migrant Mothers of Syria interactive webdoc.`
      },
      {
        thumbnail: manal_thumb8,
        original: manal_image8,
        caption: `One of the few belongings Manal kept from Syria is this doll.`
      },
      {
        thumbnail: manal_thumb9,
        original: manal_image9,
        caption: `Manal is hopeful, despite all the pain she has experienced, having lived through years of war and displacement.`
      },
      {
        thumbnail: manal_thumb10,
        original: manal_image10,
        caption: `Manal and her husband, Anas.`
      },
      {
        thumbnail: manal_thumb11,
        original: manal_image11,
        caption: `The family enjoys dinner together.`
      }
    ]
  }
}
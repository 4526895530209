import styled from 'styled-components';
import background from '../../images/MenuPattern.png';

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}
const icons = importAll(require.context("../../images/icons/", false, /\.(png|jpe?g|svg)$/));


const Component = styled.div`
  display: block;
  height: 80px;
  position: fixed;
  height: 70px;
  margin-bottom: 10px;
  width: 50%
  z-index: 1;

@media only screen and (max-width: 1050px) {
    // background: ${props => props.introViewState > 2 ? `#FFF` : 'transparent'};
    // border-bottom: ${props => props.introViewState > 2 ? `1px solid rgba(0, 0, 0, 0.2)` : 'none'};
    display: block;
    margin-bottom: 10px;
    height: 40px;
    left: 0;
    top: 0;
    width: 40%
}

.muteButton {
  position: fixed;
  top: 19px;
  right: 28px;
  display: block;
  width: 40px;
  height: 40px;
  color: red;
  background-image: url(${icons['sound-on.svg']});
  &.muted {
      color: green;
      background-image: url(${icons['sound-off.svg']});
  }
}

.slider {
  height: auto;
  cursor: pointer;
  overflow: hidden;
	max-height: 150px; /* approximate max height */

  transition:all 500ms ease;
}

.slider--open {
}

.slider--closed {
  height: auto;
  max-height: 0;
}

.skip-button {
  color: #F95757;
  text-transform: uppercase;
  font-size: 18px;
  background-image: url(${icons['skip-default.svg']});
  background-repeat: no-repeat;
  background-position-x: 0;
  background-position-y: center;
  height: 36px;
  width: 60px;
  padding-left: 40px;
  margin-top: 28px;
  margin-left: 28px;
  &.hint {
    
  }
}

.nav-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 120px;
  
  .nav-link {
    cursor: pointer;
    height: 44px;
    padding-left: 55px;
    margin-left: 5px;
    text-decoration: none;
    width: 100%;
    display: block;
    margin-bottom: 15px;
    color: #F95757;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.5px;
  }

  .nav-link-active{
    font-weight: bold;
    background-image: url(${icons["gallery-hover.svg"]})
    background-repeat: no-repeat;
  }
}

.nav-menu {
  position: fixed;

  height: 100%;
  width: 310px;
  top: 0;
  left: 0;
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.3s; /* transition effect to slide in the sidenav */
  
  background-image: url(${background})};
  text-align: left;
  margin: 0;
}

.open {
  position: fixed;
  z-index: 3000; /* Adjust to ensure modal can go above */
  
  left:0;
  top: 0;
  padding-left: 16px;
  
  @media only screen and (max-width: 1050px) {
    background-position-x: -40px;
    width: 270px;
  }
}

.closed {
  left: -310px;
  transition: 0.5s; /* 0.3 second transition effect to slide in the sidenav */
  .nav-list {
    display: none;
  }
  overflow: visible;
}

.nav-toggle-button {
  
  margin-left: 5px;
  
  left: 10px;
  top:10px;
  position: fixed;
  img {
    width: 48px;
    height: 48px;
  }
  
  @media (max-width: 1050px) {
    margin-left: 15px;
    position: fixed;
    left: 5px;
    top: 5px;
    img {
      width: 32px;
      height: 32px;
    }
  }
}

.nav-toggle-button--disabled {
  margin-left: 5px;
  left: 10px;
  top:10px;
  position: fixed;

  img {
    width: 48px;
    height: 48px;
  }

  @media (max-width: 1050px) {
    margin-left: 15px;
    position: fixed;
    left: 5px;
    top: 5px;
    img {
      width: 32px;
      height: 32px;
    }
  }
}

.link-gallery {
  background: ${props => `url(${icons["gallery-default.svg"]}) no-repeat`};
  &:hover {
        background-image: ${props => `url(${icons["gallery-hover.svg"]})`};
    }
}
.link-videos {
  background: ${props => `url(${icons["video-default.svg"]}) no-repeat`};
   &:hover {
      background-image: ${props => `url(${icons["video-hover.svg"]})`};
    }
}
.link-about {
  background: ${props => `url(${icons["about-default.svg"]}) no-repeat`};
   &:hover {
        background-image: ${props => `url(${icons["about-hover.svg"]})`};
    }
}
.link-cta {
  background: ${props => `url(${icons["cta-default.svg"]}) no-repeat`};
   &:hover {
        background-image: ${props => `url(${icons["cta-hover.svg"]})`};
    }
}
.link-credits {
  background: ${props => `url(${icons["credits-default.svg"]}) no-repeat`};
   &:hover {
        background-image: ${props => `url(${icons["credits-hover.svg"]})`};
    }
}
.link-contact {
  background: ${props => `url(${icons["contact-default.svg"]}) no-repeat`};
   &:hover {
        background-image: ${props => `url(${icons["contact-hover.svg"]})`};
    }
}
`;
export default Component;
import React, { Component } from 'react'
import {connect} from 'react-redux';
import { NavLink } from 'react-router-dom'
import classNames from 'classnames';

import NavStyle from './Nav.style'

import menuCloseIcon from '../../images/icons/menu-close-default.svg';
import menuDefaultIcon from '../../images/icons/menu-default.svg';
import menuDisabledIcon from '../../images/icons/menu-disabled.svg';


import { start } from 'pretty-error';

import {muteSubtitlesAction} from '../../redux/actions/videoView.action';

function importAll(r) {
	let images = {};
	r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
	return images;
}
const icons = importAll(require.context("../../images/icons/", false, /\.(png|jpe?g|svg)$/));

class Nav extends Component {
	constructor(props) {
		super(props);
		this.state = {showGalleries: false};
	}

	_skipAndMuteButton() {
		const muteStyle = classNames('muteButton', {muted: this.props.muted});

		return (
		<div>
			<button
				className={classNames("skip-button", {hint: this.props.showHint})}
				onClick={() => this.props.playVideos()}
			>
				Skip
			</button>
			<button
				className={muteStyle}
				onClick={this.props.toggleMute} />
		</div>
		)
	}

	_handleClick() {
		this.setState({showGalleries: false});
		this.props.toggleMenu();
	}

	_menu() {
		const menuClasses = classNames('nav-menu', {
			open: this.props.showMenu,
			closed: !this.props.showMenu
		});

		const style = {
			textDecoration: 'none',
			color: '#F95757',
			fontWeight: 'bold'
		}

		const homeActive = {
			...style,
			backgroundImage: `url(${icons["video-hover.svg"]})`
		};

		const aboutActive = {
			...style,
			backgroundImage: `url(${icons["about-hover.svg"]})`
		};

		const ctaActive = {
			...style,
			backgroundImage: `url(${icons["cta-hover.svg"]})`
		};

		const creditsActive = {
			...style,
			backgroundImage: `url(${icons["credits-hover.svg"]})`
		};

		const contactActive = {
			...style,
			backgroundImage: `url(${icons["contact-hover.svg"]})`
		};
		
		const galleryParentStyles = classNames(
			'nav-link', 'link-gallery', {
				'nav-link-active': Boolean(
					this.props.pathname === '/gallery1' ||
					this.props.pathname === '/gallery2'
				)
			}
		);

		const sliderClassnames = classNames(
			'slider', {
				'slider--open': this.state.showGalleries === true,
				'slider--closed': this.state.showGalleries === false
			}
		)

		return (
			<div className={menuClasses}>
					<span role="button" onClick={() => this._handleClick()} className='nav-toggle-button'>
					{
						this.props.showMenu ?
							<img src={menuCloseIcon} aria-label='close button' /> :
							<img src={menuDefaultIcon} aria-label='open button' />
					}
					</span>
					<div className='nav-list'>
						<NavLink className="nav-link link-videos" onClick={() => this._handleClick()} to="/home" activeStyle={homeActive}>Keep Watching</NavLink>
						<span className={galleryParentStyles} onClick={() => this.setState((prev) => ({showGalleries: !prev.showGalleries}))}>Gallery</span>
						<div className={sliderClassnames}>
								<NavLink className="nav-link" onClick={() => this._handleClick()} to="/gallery1" activeStyle={style}>Amani</NavLink>
								<NavLink className="nav-link" onClick={() => this._handleClick()} to="/gallery2" activeStyle={style}>Manal</NavLink>
						</div>
						<NavLink className="nav-link link-about" onClick={() => this._handleClick()} to="/about" activeStyle={aboutActive}>About</NavLink>
						<NavLink className="nav-link link-cta" onClick={() => this._handleClick()} to="/cta" activeStyle={ctaActive}>How to Help</NavLink>
						<NavLink className="nav-link link-credits" onClick={() => this._handleClick()} to="/credits" activeStyle={creditsActive}>Credits</NavLink>
						<NavLink className="nav-link link-contact" onClick={() => this._handleClick()} to="/contact" activeStyle={contactActive}>Contact</NavLink>
					</div>
				</div>
		)
	}

	_disabled() {
		return (
			<span role="button" onClick={() => this._handleClick()} className='nav-toggle-button--disabled'>
					<img src={menuDisabledIcon} aria-label='disabled button' />
			</span>
		)
	}

	render() {
		let menu = "";
		const stateCheck = Number(this.props.introViewState);
		if (stateCheck === 3) {
			menu = this._menu()
		}
		
		if (stateCheck === 2) {
			menu = this._skipAndMuteButton()
		}

		if (stateCheck === 1) {
			menu = ''
		}

		if (this.props.modalOpen) {
			menu = this._disabled();
		}

		return (
			<NavStyle {...this.props} id='nav-list-id'>
				{ menu }
			</NavStyle>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return({
	showMenu: state.appState.showMenu,
	showHint: state.appState.showHint,
	introViewState: state.videoView.introViewState,
	muted: state.videoView.muted,
	pathname: state.router.location.pathname,
	modalOpen: state.videoView.modalOpen
})};

const mapDispatchToProps = (dispatch, ownProps) => ({
	toggleMenu: a => dispatch({type: 'TOGGLE_MENU'}),
	toggleMute: a => dispatch(muteSubtitlesAction()),
	playVideos: a => dispatch({type: 'PLAY_VIDEOS'}) // on this, show completed puzzles
});

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
/* @flow */
import React from 'react';
import { observer } from 'mobx-react';
import { computed, decorate } from 'mobx';
import styles from './ScratchableImage.scss';
import classNames from 'classnames/bind';
const cn = classNames.bind(styles);

opaque type Props = {
    x: number;
    y: number;
    image: string; // must be preloaded
    progressCallback: ?(percent: number)=>void;
}

const shadow = {
    x: 5,
    y: 5,
    blur: 5,
    color: 'rgba(0,0,0,0.2)',
}; 

const bgOpacity = 0;
const brushSize = 75;

/**
 * Helper function to get the local coords of an event in an element,
 * since offsetX/offsetY are apparently not entirely supported, but
 * offsetLeft/offsetTop/pageX/pageY are!
 *
 * @param elem element in question
 * @param ev the event
 */
function getLocalCoords(elem: HTMLCanvasElement, ev: MouseEvent): {x: number, y: number} {
    let ox = 0, oy = 0;
    let first;
    let pageX, pageY;

    const bounds = elem.getBoundingClientRect();
    ox = bounds.left;
    oy = bounds.top;

    if (ev.changedTouches!=null) {
        first = ev.changedTouches[0];
        pageX = first.pageX;
        pageY = first.pageY;
    }
    else {
        pageX = ev.pageX;
        pageY = ev.pageY;
    }

    const scaleX = elem.width / bounds.width;
    const scaleY = elem.height / bounds.height;

    return { 'x': (pageX - ox) * scaleX, 'y': (pageY - oy) * scaleY };
};
    
    
    /**
 * Draw a scratch line
 * 
 * @param can the canvas
 * @param x,y the coordinates
 * @param fresh start a new line if true
 */
function scratchLine(can: HTMLCanvasElement, x: number, y: number, fresh: boolean) {
    const ctx = can.getContext('2d');
    ctx.lineWidth = brushSize;
    ctx.lineCap = ctx.lineJoin = 'round';
    ctx.strokeStyle = '#000'; // can be any opaque color
    if (fresh) {
        ctx.beginPath();
		// this +0.01 hackishly causes Linux Chrome to draw a
		// "zero"-length line (a single point), otherwise it doesn't
		// draw when the mouse is clicked but not moved:
        ctx.moveTo(x+0.01, y);
    }
    ctx.lineTo(x, y);
    ctx.stroke();
}

class ScratchableImage extends React.Component<Props> {
    mainCanvas: HTMLCanvasElement;
    tempCanvas: HTMLCanvasElement;
    drawCanvas: HTMLCanvasElement;

    mouseDown: boolean = false;

    get width(): number {
        return this.canvasImage.width + shadow.blur*2 || 960;
    }
    
    get height(): number {
        return this.canvasImage.height + shadow.blur*2 || 800;
    }

    get pieceX(): number {
        return -shadow.x + shadow.blur;
    }
    
    get pieceY(): number {
        return -shadow.y + shadow.blur;
    }

    percentRevealed: number = 0;

    constructor(props: any) {
        super(props);
        this.mousedown_handler = this.mousedown_handler.bind(this);
        this.mousemove_handler = this.mousemove_handler.bind(this);
        this.mouseup_handler = this.mouseup_handler.bind(this);
        this.checkIfTouchWasOnPiece = this.checkIfTouchWasOnPiece.bind(this);
        this.tempCanvas = document.createElement('canvas');
        this.drawCanvas = document.createElement('canvas');

        this.canvasImage = document.createElement("img");
        this.canvasImage.width = this.props.width || '100';
        this.canvasImage.height = this.props.height || '100';
        this.canvasImage.key = 'image-1';
        this.canvasImage.src = this.props.image;

        this.tempCanvas.width = this.width || 960;
        this.tempCanvas.height = this.height || 800;
        this.drawCanvas.width = this.width || 960;
        this.drawCanvas.height = this.height || 800;
    }

    componentDidMount() {
        if(!this.props.image) {
            console.log("missing an image path");
            return "";
        }

        this.mainCanvas.addEventListener('mousedown', this.mousedown_handler, false);
        this.mainCanvas.addEventListener('touchstart', this.mousedown_handler, false);
        window.addEventListener('mousemove', this.mousemove_handler, false);
        window.addEventListener('touchmove', this.mousemove_handler, false);
        window.addEventListener('mouseup', this.mouseup_handler, false);
        window.addEventListener('touchend', this.mouseup_handler, false);
    }

    componentWillUnmount() {
        this.mainCanvas.removeEventListener('mousedown', this.mousedown_handler);
        this.mainCanvas.removeEventListener('touchstart', this.mousedown_handler);
        window.removeEventListener('mousemove', this.mousemove_handler);
        window.removeEventListener('touchmove', this.mousemove_handler);
        window.removeEventListener('mouseup', this.mouseup_handler);
        window.removeEventListener('touchend', this.mouseup_handler);
    }

    checkIfTouchWasOnPiece(localCoords: {x: number, y: number}): boolean {
        return localCoords.x > 0
            && localCoords.x < this.canvasImage.width
            && localCoords.y > 0
            && localCoords.y < this.canvasImage.height;
    }

    /**
	 * On mouse down, draw a line starting fresh
    */
    mousedown_handler(e: MouseEvent): false {
        const local = getLocalCoords(this.mainCanvas, e);
        this.mouseDown = true;

        scratchLine(this.drawCanvas, local.x, local.y, true);

        this.recompositeCanvases();

        if (e.cancelable) { e.preventDefault() } 
        return false;
    };

	/**
	 * On mouse move, if mouse down, draw a line
	 *
	 * We do this on the window to smoothly handle mousing outside
	 * the canvas
	 */
    // eslint-disable-next-line flowtype/require-return-type
    mousemove_handler(e: MouseEvent): false {
        const local = getLocalCoords(this.mainCanvas, e);
        scratchLine(this.drawCanvas, local.x, local.y, false);
        this.recompositeCanvases();
        if (e.cancelable) { e.preventDefault() } 
        return false;
    };

	/**
	 * On mouseup.  (Listens on window to catch out-of-canvas events.)
	 */
    mouseup_handler(e: MouseEvent): boolean {
        if (this.mouseDown) {
            this.mouseDown = false;
            if (e.cancelable) { e.preventDefault() } 
            return false;
        }

        return true;
    };


    recompositeCanvases() {
        const mainContext = this.mainCanvas.getContext('2d');
        const tempContext = this.tempCanvas.getContext('2d');
        tempContext.globalCompositeOperation = 'source-over';
        mainContext.globalCompositeOperation = 'source-over';

// Step 1: clear the temp
        tempContext.clearRect(0, 0, this.width, this.height);
// clear the main canvas
        mainContext.clearRect(0, 0, this.width, this.height);

        // tempContext.clearRect(0, 0, this.props.width, this.props.height);

// Step 2 draw the line from draw to temp
        tempContext.drawImage(this.drawCanvas, 0, 0);

        if(bgOpacity > 0) {
// Step 2: draw a transparent version of the piece to the main canvas
            mainContext.save();
            mainContext.globalAlpha = bgOpacity;
            mainContext.drawImage(this.canvasImage, 0, 0); // put the piece on the drawn area        
            mainContext.restore();
        }

// Step 3: stamp the revealed piece on the temp, only in the areas where the lines were
        tempContext.save();
        tempContext.globalCompositeOperation = 'source-atop';
        tempContext.shadowOffsetX = shadow.x;
        tempContext.shadowOffsetY = shadow.y;
        tempContext.shadowColor = shadow.color;
        tempContext.shadowBlur = shadow.blur;

        tempContext.drawImage(this.canvasImage, 0, 0); // put the piece on the drawn area
        tempContext.restore();

// stamp the temp on the display canvas (source-over)
        mainContext.drawImage(this.tempCanvas, 0, 0);
// clear the temp
        tempContext.clearRect(0, 0, this.width, this.height);
// draw just the image (with shadow) to temp
        tempContext.save();
        tempContext.shadowOffsetX = shadow.x;
        tempContext.shadowOffsetY = shadow.y;
        tempContext.shadowColor = shadow.color;
        tempContext.shadowBlur = shadow.blur;
        tempContext.drawImage(this.canvasImage, 0, 0); // put the piece on the drawn area
        tempContext.restore();
// erase the lines by drawing image with shadow to main again
        mainContext.save();
        mainContext.globalCompositeOperation = 'destination-in';
        mainContext.drawImage(this.tempCanvas, 0, 0); // put the piece on the drawn area
        mainContext.restore();

// clear temp again
        tempContext.clearRect(0, 0, this.width, this.height);
// fill temp bg with (opaque) white
        tempContext.save();
        tempContext.fillStyle = "#fff";
        tempContext.fillRect(0, 0, this.width, this.height);
        tempContext.restore();
// put the lines back onto the temp canvas
        tempContext.drawImage(this.drawCanvas, 0, 0);
 
// get pixels from temp
// https://stackoverflow.com/a/28792981
        const imageData = tempContext.getImageData(0, 0, this.canvasImage.width, this.canvasImage.height);
        const pixelArray = new Uint32Array(imageData.data.buffer);   // use 32-bit buffer (faster)

// count em
        let white = 0;
        let black = 0;

        for(let i = 0; i < pixelArray.length; i++) {
            const key = "" + (pixelArray[i] & 0xffffff);           // filter away alpha channel
            if(key==0) black++;
            else if (key == 16777215) white++;
        }

        const _percentRevealed = (black) / (black+white);
        if(_percentRevealed > this.percentRevealed) {
            this.props.progressCallback(_percentRevealed);
        }
        this.percentRevealed = _percentRevealed;
    }

    render() {
        if(!this.canvasImage) {
            console.log('Error! No image supplied');
            console.log('props', this.props);
            return "";
        }
        return(
            <canvas
                ref={el=>this.mainCanvas=el}
                // style={{left: this.props.x - 0, top: this.props.y - 0}}
                className={cn('canvas')}
                width={this.width}
                height={this.height}
            >
            </canvas>
        );
    }
}

decorate(ScratchableImage, {
    width: computed,
    height: computed,
    pieceX: computed,
    pieceY: computed,
})

export default observer(ScratchableImage);

import {createGlobalStyle} from 'styled-components'

import paper_background from '../images/paper_background.jpg';
import sketch_background from '../images/sketch_drawing.png';
import amani_bg from '../images/cta/cta-background.png';

export default createGlobalStyle`
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;

  // Added for mobile devices
  -webkit-overflow-scrolling: touch;
}

a {
  text-decoration: none;
}

// .wf-loading * {
//   /*
//     hide fonts while loading
//   */
//   opacity: 0;
// }

[role="button"],
input[type="submit"],
input[type="reset"],
input[type="button"],
button {
    -webkit-box-sizing: content-box;
       -moz-box-sizing: content-box;
            box-sizing: content-box;
}

/* Reset button and button-style input default styles */
input[type="submit"],
input[type="reset"],
input[type="button"],
button {
    background: none;
    border: 0;
    color: inherit;
    /* cursor: default; */
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    -webkit-appearance: button; /* for input */
    -webkit-user-select: none; /* for button */
       -moz-user-select: none;
        -ms-user-select: none;
}
input::-moz-focus-inner,
button::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/* Make 'a' like a button */
[role="button"] {
    color: inherit;
    cursor: default;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    white-space: pre;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: #F95757;
}

.hidden {
  display: none;
}

.full-bleed {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  position: fixed;
  overflow: scroll;
}

.modal-shim {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  background: rgba(0,0,0,0.90);
}

.shim-down { /* nb: a hack to ensure the nav menu is never displayed above the modal background */
  z-index: -1
}

.cta-bg {
  //background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${amani_bg});
  background-image: url(${amani_bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  z-index: -1;
}

.paper-bg {
  background-image: ${props => `url(${paper_background})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  z-index: -1;
}

.sketch-bg {
  background-image: ${props => `url(${sketch_background})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  z-index: -1;
}

@media only screen and (max-width: 767px)  {
  .sketch-bg {
    background-image: ${props => `url(${paper_background})`};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    z-index: -1;
  }
}

.splash-container {
  max-width: 90%;
  margin: 0 auto;
  font-size: 36px;
}

@media only screen and (max-width: 1050px), (orientation: portrait) {
  .cta-bg {
    background-image: ${props => `url(${paper_background})`};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
  }
}



`;

import React, { Component } from 'react'
import {connect} from 'react-redux';
import MediaQuery from 'react-responsive';
import ScratchViewStyle from './ScratchView.style'
import ScratchableImage from '../ScratchableImage';

import bg from '../../../images/scratchable/ScratchOff.png';
import AudioWithSubtitles from '../../AudioWithSubtitles';

import {skipIntroPlayVideos} from '../../../redux/actions/videoView.action';

class ScratchView extends Component {

	audioComplete: boolean;
	scratchComplete: boolean;

	componentDidMount() {
		if(this.refs.mobileVideo!=null) {
			this.refs.mobileVideo.onended = this.OnScratchComplete;
		}
	}

	OnAudioComplete = ()=>{
		this.audioComplete = true;
		if(this.scratchComplete) {
			this.props.playVideos();
		}
		else {
			this.props.showHint();
		}
	};
	
	OnScratchComplete = ()=>{
		this.scratchComplete = true;
		if(this.audioComplete) {
			this.props.playVideos();
		}
		else {
			this.props.showHint();
		}
	};

	ScratchProgressCallback = (percent)=>{
		if (!this.scratchComplete && percent>0.8) {
			this.OnScratchComplete();
		}
	}

	render() {
		return (
				<ScratchViewStyle>
						<AudioWithSubtitles audioCompleteCallback={this.OnAudioComplete}/>
						<MediaQuery query="(min-width: 1051px)">
							<ScratchableImage
								image={bg}
								width={820}
								height={620}
								progressCallback={this.ScratchProgressCallback}
							/>
						</MediaQuery>
						<MediaQuery query="(max-width: 1050px)">
							<video ref="mobileVideo" src={require('../../../video/mmbfscratchoff1.mp4')} className={'video'} autoPlay playsInline></video>
						</MediaQuery>
				</ScratchViewStyle>
		)

	}

}
const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	playVideos: a => dispatch(skipIntroPlayVideos()), // on this, show completed puzzles
	showHint: a => dispatch({type: 'SHOW_HINT'}),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScratchView);

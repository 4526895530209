export default {
    vimeoiFrameHTMLData: null,
    vimeoLoading: false,
    vimeoLoadComplete: false,
    muted: false,
    pieceIndex: 0,
    progress: 0,
    loaded: false,
    loading: false,
    begun: false,
    modalOpen: false,
    activePiece: '',
    introViewState: 1,
    showFamilyPhotos: false,
    enableFamilyPhotos: false,
    showStickFigures: false,
    doneTheLastVideo: false,
    doneTheLastVideoText: "There is always hope.",
    "subtitles": [
        {
            "text": "My home had two rooms. There was a balcony where I planted Jasmine flowers and roses.",
            "start": 1,
            "duration": 13,
            "color": "#e69f28"
        },
        {
            "text": "There was one room for us and one for the children. It was very bright.",
            "start": 12,
            "duration": 9,
            "color": "#CB2438"
        },
        {
            "text": "There was a place for my children's toys. There were pictures of them when they were young.",
            "start": 21,
            "duration": 10,
            "color": "#e69f28"
        },
        {
            "text": "My son had many toys, he still asks me about the toys he left in that home.",
            "start": 28,
            "duration": 8,
            "color": "#CB2438"
        },
        {
            "text": "We would put up any pictures that they drew on the wall.",
            "start": 36,
            "duration": 6,
            "color": "#e69f28"
        },
        {
            "text": "This swing, I brought it with me because my son was very attached to it.",
            "start": 42,
            "duration": 7,
            "color": "#CB2438"
        },
        {
            "text": "There was a kitchen, it was very simple.",
            "start": 49,
            "duration": 5,
            "color": "#e69f28"
        },
        {
            "text": "There was a window that peered into a large square, I used to look through it.",
            "start": 54,
            "duration": 7,
            "color": "#e69f28"
        },
        {
            "text": "Of course, I miss the kitchen, I even miss the walls.",
            "start": 59,
            "duration": 8,
            "color": "#CB2438"
        }
    ],
    "pieces": [
        {
            "src": "Amani_piece2_NEW.png",
            "x": 86,
            "y": 0,
            "shuffledX": 282,
            "shuffledY": 171,
            "text": "Every home has a story.\nClick on this piece to find out more.",
            "color": "rgba(0,0,0,0.54)",
            "video": "https://vimeo.com/332205736/16d293c05e",
            "whichMum": "amani",
            "title": "Amani Ch. 1: Memories of Home",
        },
        {
            "src": "Amani_piece3_NEW.png",
            "x": 203,
            "y": 0,
            "text": "As a teacher, I struggled to find work when I came to Lebanon.",
            "shuffledX": 482,
            "shuffledY": 71,
            "color": "#CB2438",
            "video": "https://vimeo.com/332230444/953538d3e6",
            "whichMum": "amani",
            "title": "Amani Ch. 2: Daily Life",
        },
        {
            "src": "Amani_piece4_NEW.png",
            "x": 338,
            "y": 0,
            "shuffledX": 0,
            "shuffledY": 162,
            "text": "I wish for my eldest child to be given the full opportunity of an education.",
            "color": "#CB2438",
            "video": "https://vimeo.com/332253226/84ff1f5cbf",
            "whichMum": "amani",
            "title": "Amani Ch. 3: Hopes & Dreams",
        },
        {
            "src": "manal_piece2_NEW.png",
            "x": 85,
            "y": 116,
            "shuffledX": 399,
            "shuffledY": 0,
            "text": "I came here without having relatives, my siblings, my husband, so I realized I had to be strong.",
            "color": "#DB9C38",
            "video": "https://vimeo.com/332256818/3084bed81f",
            "whichMum": "manal",
            "title": "Manal Ch. 1: Memories of Home",
        },
        {
            "src": "manal_piece5_NEW.png",
            "x": 224,
            "y": 112,
            "text": "The first year I taught at the school, we had an understanding that we're all one team.",
            "shuffledX": 243,
            "shuffledY": 213,
            "whichMum": "manal",
            "color": "#DB9C38",
            "title": "Manal Ch. 2: Daily Life",
            "video": "https://vimeo.com/332266114/756feb1a7c",
        },
        {
            "src": "manal_piece3_NEW.png",
            "x": 126,
            "y": 299,
            "shuffledX": 74,
            "shuffledY": 48,
            "text": "I do wish to return to Syria, to my home, to the Assi River, to the land, to my school.",
            "color": "#DB9C38",
            "video": "https://vimeo.com/332304745/9cd67278e0",
            "whichMum": "manal",
            "title": "Manal Ch. 3: Hopes & Dreams",
        },
        {
            "src": "Amani_piece1_NEW.png",
            "x": 0,
            "y": 0,
            "shuffledX": 132,
            "shuffledY": 112,
            "whichMum": "amani",
        },
        {
            "src": "manal_piece1_NEW.png",
            "x": 0,
            "y": 96,
            "shuffledX": 645,
            "shuffledY": 127,
            "whichMum": "manal",
        },
        {
            "src": "manal_piece4_NEW.png",
            "x": 0,
            "y": 0,
            "shuffledX": 105,
            "shuffledY": 448,
            "whichMum": "manal"
        }
    ]

}

import React, {Component} from 'react';
import {connect} from 'react-redux';

import classNames from 'classnames';

import {Route, Switch, Redirect} from 'react-router-dom'

import Nav from './components/Nav';
import Gallery from './components/Gallery';
import Credits from './components/CreditsView';
import Contact from './components/ContactView';
import CTA from './components/CTAView';
import About from './components/AboutView';
import VideoView from './components/VideoViewRedux';

import GlobalStyle from './styles/globalStyles'

class App extends Component {
    render() {
        const backgroundClasses = classNames(
            "full-bleed",
            {
                "cta-bg": this.props.pathname === '/cta',
                "sketch-bg": this.props.pathname === '/credits',
                "paper-bg": ['/gallery1', '/gallery2', '/contact', '/about', '/home'].includes(this.props.pathname),
            })

        const navControl = classNames({
            'shim-down': this.props.modalOpen
        })

        return (
            <div className={backgroundClasses}>
                <GlobalStyle/>
                <Nav className={navControl}/>
                <Switch>
                    <Redirect exact from="/" to="/home" component={() => <VideoView />}/>
                    <Route path="/gallery1" exact component={() => <Gallery character='amani'/>}/>
                    <Route path="/gallery2" exact component={() => <Gallery character='manal'/>}/>
                    <Route path="/credits" exact component={() => <Credits/>}/>
                    <Route path="/about" exact component={() => <About/>}/>
                    <Route path="/cta" exact component={() => <CTA/>}/>
                    <Route path="/contact" exact component={() => <Contact/>}/>
                    <Route path="/home" exact component={() => <VideoView/>}/>
                </Switch>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    pathname: state.router.location.pathname,
    search: state.router.location.search,
    hash: state.router.location.hash
})

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(App);

import styled from 'styled-components';

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}
const images = importAll(require.context("../../images/contact/", false, /\.(png|jpe?g|svg)$/));
const socialIcons = importAll(require.context("../../images/icons/social/", false, /\.(png|jpe?g|svg)$/));

// TODO: Format scroll to top on load

const Component = styled.div`

.contact-header-background{
    @media (max-width: 767px){
      margin-top: 40px;
      height: 240px;
      margin-bottom: 30px;
    }
    
    display: flex;
    justify-content: center;
    align-items: center;
    
    margin-bottom: 61px;

    height: 380px;
    width: 100%;
    left: -20%;

    background-image: ${props => `url(${images["contact-background-desktop.png"]})`};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    
     @media (max-width: 1050px), (orientation: portrait) {
      background-image: ${props => `url(${images["contact-background-mobile.png"]})`};
     }
    
    font-family: Montserrat;
    font-weight: bold;
    font-size: 48px;
    text-transform: uppercase;
    z-index: -1;
    color: #FFF;
  }

  .contact-main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    a{
      display: block;
      text-align: center;
  
      color: #F95757;
      text-decoration: none;
      font-weight: bold;
      margin-bottom: 32px;
      font-size: 18px;
    }
  }

  .contact-tagline{
    font-weight: lighter;
    font-size: 36px;
    margin-bottom: 40px;

    @media (max-width: 767px){
      font-size: 24px;
      margin-bottom: 20px;
    }
  }

  h3{
    text-transform: uppercase;
    font-size: 18px;
    font-weight: normal;
    line-height: 27px;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 5px;

    @media (max-width: 767px){
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
  }

  .social-media{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;

    padding-left: 48px;
    margin-top: 16px;

    .sm-icon{
      margin: 0 48px 48px 0;
      height: 48px;
      width: 48px;
    }

    .facebook{
      background-image: ${props => `url(${socialIcons["facebook-default.svg"]})`};
      background-repeat: no-repeat;
      &:hover{
        background-image: ${props => `url(${socialIcons["facebook-hover.svg"]})`};
      }
    }
    .twitter{
      background-image: ${props => `url(${socialIcons["twitter-default.svg"]})`};
      background-repeat: no-repeat;
      &:hover{
        background-image: ${props => `url(${socialIcons["twitter-hover.svg"]})`};
      }
    }
    .insta{
      background-image: ${props => `url(${socialIcons["insta-default.svg"]})`};
      background-repeat: no-repeat;
      &:hover{
        background-image: ${props => `url(${socialIcons["insta-hover.svg"]})`};
      }
    }
  }
`;
export default Component;
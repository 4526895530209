import styled from 'styled-components';

const Component = styled.div`
.wrapper {
  text-align: center;
  line-height: 1.125em;
  font-family: Gaegu;
  font-weight: normal;
  @media(max-width: 768px) {
    font-size: 36px;
    line-height: 1em;
    @media(max-width: 480px) {
      font-size: 24px;
    }
  }
  line-break: strict;
    .word {
        display: inline-block;
        white-space: nowrap;
        margin-right: 0.25em;
        animation: fadeIn 1.5s;
        &.fadeOut {
          animation: fadeOut 2s forwards;
        }
      }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    to {
      opacity: 0;
    }
  }
`;

export default Component;
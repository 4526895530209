import styled from 'styled-components';
const Component = styled.div`
    .video {
        position: absolute;
        top: 205px;
        bottom: 0;
        @media(max-height: 768px) {
            bottom: auto;
        }
        left: 0;
        right: 0;
        margin: auto;
        max-width: 100%;
        z-index: -1;
    }
`;
export default Component;
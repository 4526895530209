import styled from 'styled-components';


// language=LESS
const Component = styled.div`
    
  .page {

    display: flex;
    flex-direction: row;
    justify-content: center;

    @media (max-width: 1050px), (orientation: portrait) {
      display: block;
    }
  }

  .title {
    font-family: Montserrat, sans-serif;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    font-size: 48px;
    text-align: center;
    margin-top: 64px;
    display: block;

    @media (max-width: 1050px), (orientation: portrait) {
      color: black;
      font-size: 24px;
      margin-top: 78px;
      margin-bottom: 8px;
    }
  }

  .headline {
    font-family: Roboto, sans-serif;
    font-weight: lighter;
    color: white;
    text-align: left;
    font-size: 3vw;
    padding-top: 30px;
    margin-right: 30px;

    @media screen and (max-width: 1050px), (orientation: portrait) {
      color: #f95757;
      padding: 0 15px;
      font-size: 16px;
      text-align: center;
      margin-bottom: 10px;
      margin-right: 0;

      br {
        display: none;
      }
    }
  }

  .information-header {
    font-size: 24px;
    line-height: 37px;
    font-weight: normal;
    color: white;
    text-align: center;
    margin-top: 45px;
    margin-bottom: 25px;

    @media (max-width: 1050px), (orientation: portrait) {
      color: rgba(0, 0, 0, 0.54);
      width: auto;
      margin: 41px auto 26px;
      font-weight: lighter;
      text-align: left;

      br {
        display: none;
      }
    }
  }
  
  .card-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    
    // Use CSS grid to have them take up 2 columns
    @media screen and (max-width: 1050px), (orientation: portrait) {

      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr 30px 1fr; // IE11 Gap Support
      grid-template-columns: repeat(2, 1fr);
      grid-template: "one gap two" auto / 1fr 30px 1fr;
    }

    // Move the 2 main cards back to flex on mobile so we have a single column again
    @media screen and (max-width: 635px) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
  
  .card {
    cursor: pointer;
    position: relative;
    background-color: white;
    border-radius: 8px;
    max-width: 290px;
    max-height: 290px;
    box-shadow: 0 3.4px 6.7px 0 rgba(0, 0, 0, 0.2);

    text-align: center;
    margin: 15px;

    width: 100%;
    
    @media screen and (max-width: 1050px), (orientation: portrait){
      max-width: none;
      max-height: none;
      margin: 0 0 30px 0;
      height: 100%;
    }
    
    img {
      padding-bottom: 26px;
      // "...flex items, by default, cannot be smaller than their content."
      // https://stackoverflow.com/a/40894982/11255992
      // This overrides the default and fixes the sizing of the images:
      max-width: none;
      max-height: none;
      overflow: auto;
      width: 100%;
      height: 100px;
    }
  }
  
  .last-card{
    margin-bottom: 0;
  }

  .card-content {
    color: rgba(0, 0, 0, 0.54);

    line-height: 18px;
    font-size: 12px;
    margin: 0;
    text-align: justify;
    padding: 15px 15px;
    font-style: normal;
    font-weight: normal;

    p {
      width: 100%;
    }

    a{
      outline: none;
      text-decoration: none;
    }

    @media (max-width: 1050px), (orientation: portrait) {
      line-height: 18px;
      font-size: 12px;
      margin: 0;
      text-align: justify;
      padding: 16px 16px;
      font-style: normal;
      font-weight: normal;
    }
  }

  .card-header {
    padding-top: 20px;
    background: none;
    border: 0;
    color: #f95757;
    font-size: 18px;
    text-align: center;
    letter-spacing: 1px;
    margin: 0;

    z-index: auto;
    font-weight: normal;
  }

  .labeled-card-container {
    display: -ms-grid;
    display: grid;
    grid-template-areas: "a lc-g-0 b lc-g-1 c lc-g-2 d";
    -ms-grid-columns: 1fr 30px 1fr 30px 1fr 30px 1fr; // IE 11 doesn't have amazing repeat support so this is easier.
    grid-template-columns: 1fr 30px 1fr 30px 1fr 30px 1fr; 
    margin: auto;
    width: 640px;
    padding-left: 15px;
    padding-right: 15px;

    @media (max-width: 1050px), (orientation: portrait) {
      margin: 0;
      width: auto;
      padding-left: 0;
      padding-right: 0;
    }

    @media (max-width: 635px) {
      grid-template-areas: "a lc-g-0 b"
                            "c lc-g-2 d";
      
      -ms-grid-columns: 1fr 30px 1fr; // IE 11 doesn't have amazing repeat support so this is easier.
      grid-template-columns: 1fr 30px 1fr;

      -ms-grid-rows: 1fr 30px 1fr; // IE 11 doesn't have amazing repeat support so this is easier.
      grid-template-rows: 1fr 30px 1fr;
    }
    
    img{
      height: 100%;

      @media (max-width: 1050px), (orientation: portrait) {
          width: 100%;
      }
    }
  }

  .labeled-card {
    cursor: pointer;
    height: 82px;
    
    @media (max-width: 1050px), (orientation: portrait) {
      padding-right: 0;
      padding-top: 0;
      margin-bottom: 16px;
      width: auto;
    }

    &-label {
      padding-top: 13px;
      text-align: center;
      font-family: Roboto, sans-serif;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: normal;
      color: white;

      @media (max-width: 1050px), (orientation: portrait) {
        color: rgba(0, 0, 0, 0.54);
        font-size: 16px;
        font-weight: normal;
      }
    }
  }
  
  .labeled-card-content {
    height: 100%;
    text-align: center;

    background-color: white;
    border-radius: 8px;
    box-shadow: 0 3.4px 6.7px 0 rgba(0, 0, 0, 0.2);
    
    img {
      padding: 10px;
      width: 100%;
      height: 100%;
    }
  }
  
  // Main cards & Labeled cards
  .labeled-card-link-lower-row {
    @media (max-width: 635px) {
      margin-top: 15px;
    }
  }

  .cell-a, .cell-b, .cell-c, .cell-d, .card-cell-0, .card-cell-1 {
    @media screen and (max-width: 1050px), (orientation: portrait) {
      grid-area: auto;
    }
  }

  // If we don't put the index of this to 1 in IE then this link / div won't be clickable (every other link on this page doesn't have this issue)
  @media all and (-ms-high-contrast: none) {
    .cell-a {
      z-index: 1;
    }
  }
  
    // Main cards
  .card-cell-0 {
    -ms-grid-column: 1;
    grid-area: one;
  }

  .card-gap {
    grid-area: gap;
    -ms-grid-column: 2;
  }

  .card-cell-1 {
    grid-area: two;
    -ms-grid-column: 3;
  }

  // Labeled Cards
  .cell-a {
    grid-area: a;
    -ms-grid-column: 1;
    -ms-grid-row: 1;
  }

  .labeled-card-gap-0{
    grid-area: lc-g-0;
  }

  .cell-b {
    grid-area: b;
    -ms-grid-column: 3;
    -ms-grid-row: 1;
  }

  .labeled-card-gap-1{
    grid-area: lc-g-1;

    @media (max-width: 635px){
      display: none;
    }
  }

  .cell-c {
    grid-area: c;
    -ms-grid-column: 5;

    @media (max-width: 635px){
      -ms-grid-column: 1;
      -ms-grid-row: 3;
      grid-row: 3;
    }
  }

  .labeled-card-gap-2{
    grid-area: lc-g-2;
  }

  .cell-d {
    grid-area: d;
    -ms-grid-column: 7;

    @media (max-width: 635px){
      -ms-grid-column: 3;
      -ms-grid-row: 3;
      grid-row: 3;
    }
  }

`;
export default Component;
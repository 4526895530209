// make react 16.x work in internet explorer 11
if(!('Map' in window))
    require('core-js/es6/map');
// make react 16.x work in internet explorer 11
if(!('Set' in window))
    require('core-js/es6/set');
// make class-transformer work in internet explorer 11
if(!('find' in Array.prototype))
    require('core-js/modules/es6.array.find');
// make class-transformer work in internet explorer 11
if(!('includes' in Array.prototype))
    require('core-js/modules/es7.array.includes');
// I think this is for react-dom in internet explorer 11 ?
if(!('startsWith' in String.prototype))
    require('core-js/modules/es6.string.starts-with');
// make fetch happen
if(!('fetch' in window))
    require('whatwg-fetch');
// video.js ie11
if(!('assign' in Object.prototype))
    require('core-js/modules/es6.object.assign');
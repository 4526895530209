/* @flow */
import {connect} from 'react-redux';
import React, {Component} from 'react';
import MediaQuery from 'react-responsive';

import classNames from 'classnames/bind';
import styles from './SplashPage.style.scss';
import AnimatedText from '../../AnimatedText';

import laurelYorkton from '../../../images/laurels/laurel1.png';
import laurelEdge from '../../../images/laurels/laurel2.png';
import laurelAccIs from '../../../images/laurels/laurel3.png';
import laurelAccEx from '../../../images/laurels/laurel4.png';
import laurelBerlin from '../../../images/laurels/laurel5.png';
import laurelMimesis from '../../../images/laurels/laurel6.png';

const cn = classNames.bind(styles);

type Props = {
    appState: {
        progress: number;
        loaded: boolean;
        BeginVideoPuzzle: ()=>void;
    }
}

class SplashPage extends Component<Props> {
		constructor(props: Props) {
			super(props);
			this.state = {
				fadeOut: false
			};
		}

    HandleBeginButtonClick() {
        if(this.state.fadeOut) { return; }
        this.setState({fadeOut: true})
        setTimeout(this.props.BeginVideoPuzzle, 1000);
    }

    componentDidMount() {
        // this.OnResize();
        // window.addEventListener('resize', this.OnResize);
    }

    componentWillUnmount() {
        // window.removeEventListener('resize', this.OnResize);
    }

		_loadingBar() {
			return (
				<div className={cn('loadingBarWrapper')}>
					<div className={cn('loadingBarForeground')} style={{width: `${this.props.appState.progress}%`}}>Loading</div>
					<div className={cn('loadingBarBackground')}>Loading</div>
				</div>
			)
		}

		_beginButton() {
			return (
				<button
					onClick={() => this.HandleBeginButtonClick()}
					className={cn('beginButton')}>
					Click to Begin.
				</button>
			);
		}

    render() {
        const className = cn(
            'splashPage',
            {'loading': this.props.loading},
            {'loaded': this.props.loaded},
            {'begun': this.props.begun},
            {'fadeOut': this.state.fadeOut},
        );

				const _loading = this.props.loading ? 
					this._loadingBar() :
					this._beginButton();

        return (
            <div className={className}>
				<MediaQuery query="(min-width: 1050px)">
					<div className="splash-container">
					<AnimatedText
						text={'Have you ever wondered what it\'s like to lose your home? On the next page, use your mouse or finger to scrub the screen to reveal what is underneath.'}
						color="rgba(0,0,0,0.54)"
					/>
					</div>
				</MediaQuery>
				{_loading}
				<div className="laurels">
					<img src={laurelEdge} alt="Official Selection. Doc Edge Festival 2020"></img>
					<img src={laurelBerlin} alt="Winner Merit Award: Experimental. Accolade Global Film Competition 2020"></img>
					<img src={laurelYorkton} alt="Winner Best Digital Media. Yorkton Film Festival 2020"></img>
					<img src={laurelAccEx} alt="Winner Merit Award: Experimental. Accolade Global Film Competition 2020"></img>
					<img src={laurelAccIs} alt="Winner Merit Award: Islamic. Accolade Global Film Competition 2020"></img>
					<img src={laurelMimesis} alt="Winner Best Documentary Arts. Mimesis Documentary Festival 2020"></img>
				</div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
	loading: state.videoView.loading,
	loaded: state.videoView.loaded,
	begun: state.videoView.begun
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	BeginVideoPuzzle: (a) => dispatch({type: 'BEGIN_VIDEO_PUZZLE'})
});
export default connect(mapStateToProps, mapDispatchToProps)(SplashPage);

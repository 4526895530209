import axios from 'axios';

export const GO_TO_PART_TWO = "GO_TO_PART_TWO";
export const GO_TO_PART_TWO_END = "GO_TO_PART_TWO_END";
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const SET_NEXT_VIDEO = "SET_NEXT_VIDEO";
export const SET_ACTIVE_PIECE = "SET_ACTIVE_PIECE";
export const SET_TEXT_COMPLETE = "SET_TEXT_COMPLETE";
export const SET_LETTER_ANIMATION_COMPLETE = "SET_LETTER_ANIMATION_COMPLETE";
export const BEGIN_VIDEO_PUZZLE = "BEGIN_VIDEO_PUZZLE";
export const PLAY_VIDEOS = "PLAY_VIDEOS";
export const GO_TO_NEXT_VIDEO = "GO_TO_NEXT_VIDEO";
export const SHOW_FAMILY_PHOTOS = "SHOW_FAMILY_PHOTOS";
export const HIDE_FAMILY_PHOTOS = "HIDE_FAMILY_PHOTOS";
export const SET_CURRENT_PIECE_TO_VISITED = "SET_CURRENT_PIECE_TO_VISITED";
export const MUTE_SUBTITLES = "MUTE_SUBTITLES";
export const ENABLE_FAMILY_PHOTOS = "ENABLE_FAMILY_PHOTOS";
export const DISABLE_FAMILY_PHOTOS = "DISABLE_FAMILY_PHOTOS";

export function muteSubtitlesAction() {
  return {
    type: MUTE_SUBTITLES
  }
}

export function skipIntroPlayVideos() {
  return {
    type: PLAY_VIDEOS
  }
}

export function OnTextComplete() {
  return {
    type: SET_TEXT_COMPLETE
  }
}

export function OnAnimationComplete() {
  return {
    type: SET_LETTER_ANIMATION_COMPLETE
  }
}
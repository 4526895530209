import * as Action from "../actions/videoView.action";
import initialState from '../../components/VideoViewRedux/VideoInitialState';


function returnState(state, action) {
  return { ...state };
}

function allPiecesRevealed (state, action) {
  return {

  }
}

function activePiece (state, action) {
  return {
    
  }
}

function prettyProgress (state, action) {
  return {
  ...state,
  progressString: action.progress.toFixed(0) + "%"
  }
}

function goToPartTwoReducer(state, action) {
    return {
      ...state,
      pieceIndex: 0,
      pieces: state.pieces.map((m) => ({...m, revealed: true}))
    }
}

function goToPartTwoEndReducer(state, action){
  return {
    ...state,
    pieceIndex: state.pieces.length,
    doneLastVideoPiece: true,
    doneTheLastVideo: true,
    pieces:  state.pieces.map((m) => ({
      ...m,
      revealed: true,
      visited: true
    }))
  }
}

function openModalReducer(state, action) {
  if(!action.piece.video) {
    return {
      ...state
    }
  };
  
  return {
    ...state,
    activeVideo: action.piece.video,
    modalOpen: true
  }
}

function setCurrentPieceToVisitedReducer(state, action) {
  let {pieces, pieceIndex} = state;
  pieces[pieceIndex].visited = true;
  return {
    ...state,
    pieces,
  }
}

function goToNextVideoReducer(state, action) {
  const _state = setCurrentPieceToVisitedReducer(state, action);
  let {pieceIndex} = _state;
  pieceIndex++;
  return {
    ..._state,
    pieceIndex,
  }
}

function showFamilyPhotosReducer(state, action){
  return {
    ...state,
    doneTheLastVideo: true,
    showFamilyPhotos: true,
  }
}

function hideFamilyPhotosReducer(state, action){
  const _state = setAllPiecesToVisitedReducer(state, action);
  return {
    ...state,
    pieces: [..._state.pieces], // TODO: this feels really janky
    pieceIndex: _state.pieceIndex,
    showFamilyPhotos: false,
    showStickFigures: true,
  }
}

function enableFamilyPhotosReducer(state, action){
  return {
    ...state,
    enableFamilyPhotos: true,
  }
}

function disableFamilyPhotosReducer(state, action){
  return {
    ...state,
    enableFamilyPhotos: false,
  }
}

function setAllPiecesToVisitedReducer(state, action){
  let {pieces, pieceIndex} = state;
  for(let p = pieceIndex; p < pieces.length; p++) {
    pieces[p].visited = true;
  }
  pieceIndex = pieces.length;
  return {
    ...state,
    pieceIndex,
    pieces
  }
}

function closeModalReducer(state, action){
  return {
    ...state,
    modalOpen: false,
    vimeoiFrameHTMLData: null
  }
}

function beginScratchPuzzle(state, action){
  return {
    ...state,
    introViewState: 2
  }
}

function playVideos(state, action){
  return {
    ...state,
    pieceIndex: 0,
    introViewState: 3
  }
}

function muteSubtitles(state, action){
  // N.B: we're toggling enough things that a generic Toggle reducer could go here.
  return {
    ...state,
    muted: !state.muted
  }
}

const options = {
  [Action.GO_TO_PART_TWO]: goToPartTwoReducer,
  [Action.GO_TO_PART_TWO_END]: goToPartTwoEndReducer,
  [Action.OPEN_MODAL]: openModalReducer,
  [Action.CLOSE_MODAL]: closeModalReducer,
  [Action.SET_CURRENT_PIECE_TO_VISITED]: setCurrentPieceToVisitedReducer,
  [Action.GO_TO_NEXT_VIDEO]: goToNextVideoReducer,
  [Action.BEGIN_VIDEO_PUZZLE]: beginScratchPuzzle,
  [Action.PLAY_VIDEOS]: playVideos,
  [Action.SHOW_FAMILY_PHOTOS]: showFamilyPhotosReducer,
  [Action.MUTE_SUBTITLES]: muteSubtitles,
  [Action.HIDE_FAMILY_PHOTOS]: hideFamilyPhotosReducer,
  [Action.ENABLE_FAMILY_PHOTOS]: enableFamilyPhotosReducer,
  [Action.DISABLE_FAMILY_PHOTOS]: disableFamilyPhotosReducer,
};

export default (state = initialState, action = {}) => {
  const func = options[action.type] || returnState;
  return func(state, action);
};
import styled from 'styled-components';

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}
const images = importAll(require.context("../../images/contact/", false, /\.(png|jpe?g|svg)$/));
const socialIcons = importAll(require.context("../../images/icons/social/", false, /\.(png|jpe?g|svg)$/));

const Component = styled.div`
.social-media-splash {
    position: fixed;
    top: 0px;
    right: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    
    padding-left: 48px;
    margin-top: 16px;
    
    @media only screen and (max-width: 1050px) {
      top: -5px;
      right: 0;
    }
    
    .sm-icon{
      margin: 0 18px 18px 0;
      height: 40px;
      width: 40px;
      background-size: contain;

      &:hover{
        text-decoration: none;
      }

      @media only screen and (max-width: 1050px) {
        margin: 0 12px 12px 0;
        height: 30px;    
        width: 30px;
        background-size: 30px 30px;
        z-index: 3;
      }
    }

    .facebook{
      background-image: ${props => `url(${socialIcons["facebook-hover.svg"]})`};
      background-repeat: no-repeat;
      &:hover{
        background-image: ${props => `url(${socialIcons["facebook-default.svg"]})`};
      }
    }
    .twitter{
      background-image: ${props => `url(${socialIcons["twitter-hover.svg"]})`};
      background-repeat: no-repeat;
      &:hover{
        background-image: ${props => `url(${socialIcons["twitter-default.svg"]})`};
      }
    }
    .insta{
      background-image: ${props => `url(${socialIcons["insta-hover.svg"]})`};
      background-repeat: no-repeat;
      &:hover{
        background-image: ${props => `url(${socialIcons["insta-default.svg"]})`};
      }
    }
  }
`;
export default Component;
/*
 src/reducers/rootReducer.js
*/
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'

import appState from './reducers/appState.reducer';
import videoView from './reducers/videoView.reducer';

export default (history) => combineReducers({
 router: connectRouter(history),
  appState,
  videoView
});
import React, { Component } from 'react'
import {connect} from 'react-redux';
import ContactViewStyle from './ContactView.style'

import {skipIntroPlayVideos} from '../../redux/actions/videoView.action';

class ContactView extends Component {

	componentDidMount() {
		// handle pre-scrolled pages on mobile
		window.scrollTo(0,0);
		
		if(this.props.introViewState !== 3) {
			// set the intro state so that we can see the nav menu and skip load
			this.props.setIntroState();
		}
	}

	render() {
		return (
			<ContactViewStyle>
			<div className="contact-header-background">
				Contact
    	</div>

			<div className='contact-main'>
					<h2 className="contact-tagline">We'd love to hear from you.</h2>
					<h3>Share your thoughts on this interactive&nbsp;experience</h3>
					<a href="mailto:info@morningbirdpictures.com">info@morningbirdpictures.com</a>
					<h3>Find us on</h3>

					<div className="social-media">
							<a className="sm-icon facebook" href="https://www.facebook.com/morningbirdpics/" role="button" target="_blank" rel="noopener noreferrer">
								&nbsp;
							</a>
							<a className="sm-icon twitter" href="https://twitter.com/morningbirdpics" role="button" target="_blank" rel="noopener noreferrer">
								&nbsp;
							</a>
							<a className="sm-icon insta" href="https://www.instagram.com/morningbirdpics/" role="button" target="_blank" rel="noopener noreferrer">
								&nbsp;
							</a>
					</div>
			</div>
			</ContactViewStyle>
		)

	}

}

const mapStateToProps = (state) => ({
	introViewState: state.videoView.introViewState,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	setIntroState: a => dispatch(skipIntroPlayVideos()), // on this, show completed puzzles
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactView);
import React, { Component } from 'react'
import {connect} from 'react-redux';
import ModalStyle from './Modal.style'
import Player from "@vimeo/player";

class Modal extends Component {
	constructor(props) {
		super(props);
		this._HandleClose = this._HandleClose.bind(this);
		this._HandleKeyPress = this._HandleKeyPress.bind(this);
	}

	componentDidMount() {
		if(this.props.activeVideo) {
			this.loadVideo(this.props.activeVideo);
		}

		document.addEventListener('keydown', this._HandleKeyPress);
  }

	componentWillUnmount() {
    document.removeEventListener('keydown', this._HandleKeyPress);
	}

	loadVideo = (url)=>{
		const player = new Player(this.refs.vimeoDiv, {
			url,
			autoplay: true,
			responsive: true,
		});
		player.on('ended', this._HandleClose);
	}

	_HandleKeyPress = (e) => {
		if(e.key === 'Escape') {
			this._HandleClose();
		}
	}

	_HandleClose = () => {
		this.props.CloseModal();
		this.props.OnModalClosed();
	}

	render() {
		// only show the modal once the video has loaded
		// this will make the site seem slow, so we may need to pre-load vimeo links
		return <>
			{this.props.modalOpen ? <div className='modal-shim' onClick={this._HandleClose}></div>: ''}
			<ModalStyle {...this.props}>
					<button className="close-button" onClick={this._HandleClose} aria-label='close modal'/>
					<div className="video-box" ref="vimeoDiv"></div>
			</ModalStyle>
			</>
	}

}
const mapStateToProps = (state) => ({
	modalOpen: state.videoView.modalOpen,
	activeVideo: state.videoView.activeVideo,
	vimeoiFrameHTMLData: state.videoView.vimeoiFrameHTMLData,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    CloseModal: (a) => dispatch({type: 'CLOSE_MODAL'}),
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);

import React, {Component} from 'react'
import {connect} from 'react-redux';
import GalleryStyle from './Gallery.style'
import "react-image-gallery/styles/css/image-gallery.css";

import ReactMarkdown from 'react-markdown';
import ImageGallery from 'react-image-gallery';
import images from './GalleryImageImports';

import MediaQuery from 'react-responsive';

import {skipIntroPlayVideos} from '../../redux/actions/videoView.action';

class Gallery extends Component {
    constructor() {
        super();
        this.state = {slideNumber: 0};
    }

    componentDidMount() {	
		if(this.props.introViewState !== 3) {
			// set the intro state so that we can see the nav menu and skip load
			this.props.setIntroState();
		}
    }

    _mobileDataSet(character) {
        return [images[character].mobile, ...images[character].images]
    }

    _desktopDataSet(character) {
        return [images[character].desktop, ...images[character].images]
    }

    _gallery(mediaQuery) {
        const {character} = this.props;
        const dataSet = (mediaQuery === 'desktop') ?
            this._desktopDataSet(character) :
            this._mobileDataSet(character);

        return (
            <GalleryStyle>
                <div className="col-xs-12 col-sm-10 offset-sm-1 col-md-10 offset-md-1 col-lg-8 offset-lg-2"  style={{paddingLeft: "0px", paddingRight: "0px"}}>
                    <ImageGallery
                        ref={i => this._imageGallery = i}
                        items={dataSet}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        showBullets={false}
                        defaultImage={dataSet[0].original}
                        onSlide={() => {
                            this.setState({slideNumber: this._imageGallery.getCurrentIndex() || 0})
                        }}
                    />
                    {
                        (!dataSet[this.state.slideNumber].caption) ?
                            "":
                            (
                                <div className="image-gallery-captions-container">
                                    <ReactMarkdown className="gallery-caption"
                                    source={dataSet[this.state.slideNumber].caption}
                                    />
                                </div>
                            )
                    }
                </div>
            </GalleryStyle>
        )
    }

    render() {
        return (
            <>
                <MediaQuery query="(min-width: 1051px)">
                    {this._gallery('desktop')}
                </MediaQuery>
                <MediaQuery query="(max-width: 1050px)">
                    {this._gallery('mobile')}
                </MediaQuery>
            </>
        )

    }

}

const mapStateToProps = (state) => ({
    introViewState: state.videoView.introViewState
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    setIntroState: a => dispatch(skipIntroPlayVideos()) // on this, show completed puzzles
});


export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
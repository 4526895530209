/*
 src/reducers/simpleReducer.js
*/

export default (state = {}, action) => {
  switch (action.type) {
   case 'TOGGLE_MENU':
    return {
      ...state,
     showMenu: !state.showMenu
    }
    case 'SHOW_HINT':
      return {
        ...state,
        showHint: true,
      }
    case 'SHOW_MENU':
      return {
        ...state,
        showMenu: true,
      }
   default:
    return state
  }
 }
/* @flow */
import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable, action, runInAction, autorun, decorate } from 'mobx';
import styles from './StackableImage.scss';
import classNames from 'classnames/bind';
const cn = classNames.bind(styles);

opaque type Props = {
    x: number;
    y: number;
    shuffledX: number;
    shuffledY: number;
    image: HTMLImageElement; // must be preloaded
    active: boolean;
    visited: boolean;
    clickable: boolean;
    visible: boolean;
    hoverText?: string;
    clickCallback: () => void;
}

class StackableImage extends React.Component<Props> {
    
    visible: boolean = false;
    x: number;
    y: number;

    // bind event listeners
    constructor(props: any) {
        super(props);

        if(!this.props.visited) {
            runInAction("StackableImage.constructor", ()=>{
                this.visible = this.props.visible || false;
            });
            setTimeout(()=>{
                runInAction("StackableImage.constructor setTimeout", ()=>{
                    this.visible = true;
                });
            }, Math.random()*500);
            runInAction("StackableImage.constructor", ()=>{
                this.x = this.props.shuffledX;
                this.y = this.props.shuffledY;
            });
        }
        else {
            runInAction("StackableImage.constructor", ()=>{
                this.visible = true;
            });
        }

        this.HandleClick = this.HandleClick.bind(this);

        autorun(()=>{
            if(this.props.visited) {
                runInAction("StackableImage.constructor autorun", ()=>{
                    this.x = this.props.x;
                    this.y = this.props.y;        
                });
            }
        });
    }

    // add event listeners
    componentDidMount() {

    }

    // remove event listeners
    componentWillUnmount() {
    }

    HandleClick() {
        this.props.clickCallback();
    }

    render() {

        const imageClass = cn(
            'image',
            {
                'in': this.visible,
                clickable: this.props.clickable,
                active: this.props.active,
                visited: this.props.visited,
            }
        );

        return <div className={imageClass} style={{left: this.x, top: this.y}} onClick={this.props.clickable ? this.HandleClick : null}>
            <img
                src={this.props.image}
                title={this.props.clickable && !!this.props.hoverText ? this.props.hoverText : ''}
            />
        </div>;
    }
}

decorate(StackableImage, {
    visible: observable,
    x: observable,
    y: observable
})

export default observer(StackableImage);

/* @flow */
import React from 'react';
import { observable, action, computed, decorate } from 'mobx';
import { observer } from 'mobx-react';
import {connect} from 'react-redux';
import AnimatedText from '../AnimatedText';
import styles from './AudioWithSubtitles.scss';
import {Howler, Howl} from 'howler';
import type Subtitle from '../../models/Subtitle';

import {skipIntroPlayVideos} from '../../redux/actions/videoView.action';

import voiceoverDotMp3 from '../../audio/MMS Intro Final WEB.mp3';
import voiceoverDotOgg from '../../audio/MMS Intro Final WEB.ogg';

import classNames from 'classnames/bind';
const cn = classNames.bind(styles);

opaque type Props = {
    subtitles: Subtitle[];
    voiceoverSrc: string | string[];
    audioCompleteCallback: ()=>void;
}


class AudioWithSubtitles extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
        this.GetAudioPosition = this.GetAudioPosition.bind(this);
        this.OnAudioComplete = this.OnAudioComplete.bind(this);
        this.Play = this.Play.bind(this);
    }

    playing: boolean = false;
    time: number = 0;

    soundInstance: any;
    soundInstanceId: any;
    raf: number;
    _isMounted: boolean = false;

    get CurrentSubtitle() {
        let ret = null;
        for(let i=0; i<this.props.subtitles.length; i++) {
            const _subtitle: Subtitle = this.props.subtitles[i];
            const end = _subtitle.start + _subtitle.duration;
            if(_subtitle.start <= this.time && end >= this.time) {
                ret = _subtitle;
                break;
            }
        }
        return ret;
    }
    
    get fadeOut() {
        if(this.CurrentSubtitle!=null) {
            const end = this.CurrentSubtitle.start + this.CurrentSubtitle.duration;
            if(end - this.time < 2) { // two seconds before the subtitle disappears
                return true;
            }
        }
        return false;
    }

    ToggleMute() {
        this.soundInstance.mute(!this.soundInstance.mute());
        this.muted = this.soundInstance.mute();
    }

    GetAudioPosition() {
        this.time = this.soundInstance.seek();
        if(this.playing) {
            this.raf = window.requestAnimationFrame(this.GetAudioPosition);
        }
    }

    OnAudioComplete() {
        this.playing = false;
        this.props.audioCompleteCallback();
    }

    Play() {
        this.soundInstance.play();
        if(!this.props.muted){
            this.playing = true;
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.soundInstance = new Howl({src: [voiceoverDotMp3, voiceoverDotOgg], onend: this.OnAudioComplete, autoplay: false});
        this.soundInstance.once('load', ()=>{
          if(this._isMounted) {
            this.Play();
          }
          this.GetAudioPosition();
        });
    }

    componentWillUnmount() {
        if(this.soundInstance!=null) {
            this.soundInstance.stop(this.soundInstanceId);
        }
        window.cancelAnimationFrame(this.raf);
        this._isMounted = false;
    }

    render() {
        if(this.soundInstance){
            if(this.props.muted) {
            this.soundInstance.mute(true, this.soundInstanceId);
            } else {
                this.soundInstance.mute(false, this.soundInstanceId);
            }
        }

        if(this.CurrentSubtitle==null) return null;
        const {text, color} = this.CurrentSubtitle;

        return (
            <div className={cn('subtitles')}>
                <AnimatedText key={text} color={color} text={text} fadeOut={this.fadeOut}/>
            </div>
        );
    }
}

decorate(AudioWithSubtitles,{
    GetAudioPosition: action,
    OnAudioComplete: action,
    Play: action,
    CurrentSubtitle: computed,
    fadeOut: computed,
    playing: observable,
    time: observable
})

const mapStateToProps = (state) => ({
    subtitles: state.videoView.subtitles,
    voiceoverSrc: state.videoView.voiceoverSrc,
    muted: state.videoView.muted
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	playVideos: a => dispatch(skipIntroPlayVideos()) // on this, show completed puzzles
});

export default connect(mapStateToProps, mapDispatchToProps)(observer(AudioWithSubtitles));

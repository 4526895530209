import React, {Component} from 'react'
import {connect} from 'react-redux';
import CTAViewStyle from './CTAView.style'
import importAll from '../../utils/importAll';

import {skipIntroPlayVideos} from '../../redux/actions/videoView.action';


const images = importAll(require.context("../../images/cta/", false, /\.(png|jpe?g|svg)$/));

function openURLTab(url)
{
    window.open(url, '_blank')
}

class CTAView extends Component {

    componentDidMount() {
        if (this.props.introViewState !== 3) {
            // set the intro state so that we can see the nav menu and skip load
            this.props.setIntroState();
        }
    }

    render() {
        return (
            <CTAViewStyle>
                <h1 className='title'>How to Help</h1>
                <main>
                    <div className="page">
                        <h2 className='headline'>
                            Your support <br/>can make a <br/>difference.
                        </h2>

                        <div className="card-content">
                            <div className="card-container">
                                <div className="card card-cell-0" onClick={() => openURLTab('https://www.tuyooralamal.com/')}>
                                    <span className="card-header">Tuyoor Al Amal</span>
                                    <p className="card-content">
                                        Through her work, Manal has helped provide education and hope for over a
                                        thousand Syrian
                                        children attending Tuyour Al Amal school in Tripoli, Lebanon. Click here to
                                        find out how
                                        to support this school and the programs it offers to children and youth.
                                    </p>
                                    <img src={images["tuyour-al-amal.svg"]} alt="Tuyour Al Amal Logo" title="Tuyour Al Amal Logo"/>
                                </div>
                                <div className="card-gap"> </div>
                                <div className="card card-cell-1 last-card" onClick={() => openURLTab('https://multiaidprograms.org/')}>
                                    <span className="card-header">Multi Aid Programs (MAPS)</span>
                                    <p className="card-content">
                                        Amani’s family is one of many who contribute to and benefit from the
                                        educational, health
                                        and relief services provided by Multi Aid Programs (MAPS). Your support will
                                        go a long
                                        way in helping families like Amani’s cope with the challenges they face as
                                        refugees in
                                        Lebanon.
                                    </p>
                                    <img src={images["maps-logo.svg"]} alt="Multi Aid Programs Logo"
                                         title="Multi Aid Programs Logo"/>
                                </div>
                            </div>
                            <h3 className="information-header">
                                Other NGOs that work towards the empowerment and <br/> education of women and children
                                in Lebanon include:
                            </h3>

                            <div className="labeled-card-container">
                                <div className="labeled-card cell-a" onClick={() => openURLTab('https://www.facebook.com/Ninurta.beirut/')}>
                                    <div className="labeled-card-content">
                                        <img src={images["ninurla.svg"]} alt="Ninurta Logo" title="Ninurta Logo"/>
                                    </div>
                                    <p className="labeled-card-label">
                                        Ninurta
                                    </p>
                                </div>
                                <div className="labeled-card-gap-0"></div>
                                <div className="labeled-card cell-b" onClick={() => openURLTab('https://www.artofhopeglobal.org/')}>
                                    <div className="labeled-card-content">
                                        <img src={images["art-of-hope.svg"]} alt="Art of Hope Logo"
                                             title="Art of Hope Logo"/>
                                    </div>
                                    <p className="labeled-card-label">
                                        Art of Hope
                                    </p>
                                </div>
                                <div className="labeled-card-gap-1"></div>
                                <div className="labeled-card cell-c" onClick={() => openURLTab('https://www.facebook.com/pg/lynlb/about/?ref=page_internal')}>
                                    <div className="labeled-card-content">
                                        <img src={images["lebanese-youth-network.svg"]}
                                             alt="Lebanese Youth Network Logo"
                                             title="Lebanese Youth Network Logo"/>
                                    </div>
                                    <p className="labeled-card-label">
                                        Lebanese<br/>Youth Network
                                    </p>
                                </div>
                                <div className="labeled-card-gap-2"></div>
                                <div className="labeled-card cell-d" onClick={() => openURLTab('https://www.catalyticaction.org')}>
                                    <div className="labeled-card-content">
                                        <img src={images["catalytic-action.svg"]} alt="Catalytic Action Logo"
                                             title="Catalytic Action Logo"/>
                                    </div>
                                    <p className="labeled-card-label">
                                        Catalytic Action
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </CTAViewStyle>
        )

    }

}

const mapStateToProps = (state) => ({
    introViewState: state.videoView.introViewState,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    setIntroState: a => dispatch(skipIntroPlayVideos()), // on this, show completed puzzles
});

export default connect(mapStateToProps, mapDispatchToProps)(CTAView);
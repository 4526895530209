import React, { Component } from 'react'
import {connect} from 'react-redux';
import VideoViewStyle from './VideoView.style'
import importAll from '../../utils/importAll';

import SplashPage from './SplashPage';
import ScratchView from './ScratchView';
import StackView from './StackView';

import {skipIntroPlayVideos} from '../../redux/actions/videoView.action';

const images = importAll(require.context("../../images/scratchable/", false, /\.(png|jpe?g|svg)$/));

class VideoView extends Component {

	componentDidMount() {
	}

	render() {
		const _splash = (this.props.introViewState === 1) ? <SplashPage /> : "";
		const _scratchoff = (this.props.introViewState === 2) ? <ScratchView /> : "";
		const _stack = (this.props.introViewState === 3) ? <StackView /> : "";

		return (
			<VideoViewStyle>
				{_splash}
				{_scratchoff}
				{_stack}
			</VideoViewStyle>
		)

	}

}

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
  hash: state.router.location.hash,
  introViewState: state.videoView.introViewState,
  pieces: state.videoView.pieces,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	playVideos: a => dispatch(skipIntroPlayVideos()) // on this, show completed puzzles
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoView);
import React, { Component } from 'react'
import {connect} from 'react-redux';
import AboutViewStyle from './AboutView.style'

import logo from '../../images/logos/MMS_LOGO_V4.jpg';

import {skipIntroPlayVideos} from '../../redux/actions/videoView.action';
class AboutView extends Component {

	componentDidMount() {
		// handle pre-scrolled pages on mobile
		window.scrollTo(0,0);
			
		if(this.props.introViewState !== 3) {
			// set the intro state so that we can see the nav menu and skip load
			this.props.setIntroState();
		}
	}

	render() {
		return (
			<AboutViewStyle>
				<h4 className="about-title">About</h4>
				<div className="about-content">
					<div className="about-logo">
						<img src={logo} alt="Migrant Mothers Logo"/>
					</div>

					<div className="about-text">
						<p className="headline">
							<em>Migrant Mothers of Syria</em> is the first installment of an interactive digital documentary series that focuses on experiences of exile and migration through a female lens.
						</p>
						<p>
							Inspired by the iconic 1936 photograph, <em>Migrant Mother</em>, by American photographer Dorothea Lange, <em> Migrant Mothers of Syria</em> seeks to bring the Syrian refugee crisis out of the realm of numbers and statistics and engage audiences with personal stories about life in between places -- told from the POV of mothers whose lives have been uprooted by conflict.
						</p>
						<p>
							Through the universal theme of motherhood, the interactive documentary reflects on notions of home, migration and exile as being part of a shared human experience that is not confined to any given race, culture or religion but that ultimately affects us all.
						</p>
						<p>
							For more information on the project, contact:<br/>
							<a href="mailto:info@morningbirdpictures.com">info@morningbirdpictures.com</a>
						</p>
					</div>
				</div>
			</AboutViewStyle>
		)

	}

}
const mapStateToProps = (state) => ({
	introViewState: state.videoView.introViewState
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	setIntroState: a => dispatch(skipIntroPlayVideos()), // on this, show completed puzzles
});

export default connect(mapStateToProps, mapDispatchToProps)(AboutView);

import React, { Component } from 'react'
import {connect} from 'react-redux';
import {action, computed, observable, decorate} from 'mobx';
import {observer} from 'mobx-react';
import StackableImage from '../StackableImage';
import importAll from '../../../utils/importAll';
import Modal from '../../../components/Modal';

import AnimatedText from '../../AnimatedText';

import styles from './StackView.scss';
import classNames from 'classnames/bind';
const cn = classNames.bind(styles);

const images = importAll(require.context("../../../images/scratchable/", false, /\.(png|jpe?g|svg)$/));

const manalFamilyPhoto = images["Manal_family_photo-not_high_res.png"];
const amaniFamilyPhoto = images["Amani_family_photo_not_high_res.png"];

class StackView extends Component {

    screenWidth: number;
    screenHeight: number;
    OnResize = action(()=>{
        this.screenWidth = window.innerWidth;
        this.screenHeight = window.innerHeight;
    });
    
    get scale(): number {
        const scale = Math.min(this.screenWidth / 820, 1);
        return scale;
    }
    
    get height(): number {
        const height = this.scale * 620;
        return height;
    }

    get isMobile(): boolean {
        return this.screenWidth <= 1022;
    }
   
    get amaniLeft(): number {
        return this.isMobile ? 175 : -100;
    }

    get manalLeft(): number {
        return this.isMobile ? 175 : 470;
    }

    get amaniTop(): number {
        return this.isMobile ? -150 : 0;
    }
    
    get manalTop(): number {
        return this.isMobile ? 350 : 0;
    }

    constructor(props) {
        super(props);
        this.OnResize = this.OnResize.bind(this);        
        // Create ref for modal
        this.modalRef = React.createRef()
    }

	componentDidMount() {
        window.addEventListener('resize', this.OnResize);
        this.OnResize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.OnResize);
    }

    OnModalClosed = () => {
        const pieces = this.props.pieces;
        const activePiece = pieces[this.props.pieceIndex];
        const nextPiece = pieces[this.props.pieceIndex+1];
        const wasThisPieceAlreadyVisited = activePiece!==undefined && activePiece.visited === true;
        const isThisTheLastVideoPiece = nextPiece!==undefined && nextPiece.video === undefined;
        if(!wasThisPieceAlreadyVisited && !isThisTheLastVideoPiece && activePiece!==undefined) {
            this.props.GoToNextPiece();
        }
        else if(isThisTheLastVideoPiece) {
            this.props.GoToNextPiece();
            this.props.EnableFamilyPhotos();
            setTimeout(()=>this.props.ShowFamilyPhotos(), 1500);
            setTimeout(()=>this.props.HideFamilyPhotos(), 4500);
            setTimeout(()=>this.props.DisableFamilyPhotos(), 6000);
            setTimeout(()=>this.props.ShowMenu(), 6000);
        }
    }

	render() {

        const _familyPhotos = this.props.enableFamilyPhotos ? 
            <div className={cn('shuffledPiecesWrapper')} style={{height: this.height}}>
                <div style={{'transform': `scale(${this.scale})`, transformOrigin: 'left'}}>
                    <img className={cn('familyPhoto', {'visible': this.props.showFamilyPhotos})} style={{top:`${this.manalTop}px`, left: `${this.manalLeft}px`}} src={manalFamilyPhoto}/>
                    <img className={cn('familyPhoto', {'visible': this.props.showFamilyPhotos})} style={{top:`${this.amaniTop}px`, left: `${this.amaniLeft}px`}} src={amaniFamilyPhoto}/>
                </div>
            </div>
            : "";

        const _stickFigures = this.props.showStickFigures ?
            <div className={cn('shuffledPiecesWrapper')} style={{height: this.height, pointerEvents: 'none'}}>
                <div style={{'transform': `scale(${this.scale})`, transformOrigin: 'left'}}>
                    <img className={cn('stickFigure')} style={{top:`${this.amaniTop + 175}px`, left: `${this.amaniLeft + 242}px`}} src={images["Amani figures.png"]} />
                    <img className={cn('stickFigure')} style={{top:`${this.manalTop + 248}px`, left: `${this.manalLeft + 112}px`}} src={images["Manal figures.png"]} />
                </div>
            </div>
            : "";

		return (
            <>
                <div className="social-media-splash">
                        <a className="sm-icon facebook" href="https://www.facebook.com/MigrantMothers/" role="button" target="_blank" rel="noopener noreferrer">
                            &nbsp;
                        </a>
                        <a className="sm-icon twitter" href="https://twitter.com/migrant_mothers" role="button" target="_blank" rel="noopener noreferrer">
                            &nbsp;
                        </a>
                        <a className="sm-icon insta" href="https://www.instagram.com/migrantmothers/" role="button" target="_blank" rel="noopener noreferrer">
                            &nbsp;
                        </a>
                </div>
                {
                (!!this.props.pieces[this.props.pieceIndex] && !!this.props.pieces[this.props.pieceIndex].text) ?    
                <div className={cn('text')}><AnimatedText
                    key={this.props.pieces[this.props.pieceIndex].text}
                    text={this.props.pieces[this.props.pieceIndex].text}
                    color={this.props.pieces[this.props.pieceIndex].color}
                    OnTextComplete={() => {}}
                /></div> : ""
                }
                { this.props.doneTheLastVideo && <div className={cn('text')}><AnimatedText text={this.props.doneTheLastVideoText} color="rgba(0,0,0,0.54)" /></div> }
                <div className={cn('shuffledPiecesWrapper')} style={{height: this.height || 810}}>
                    <div style={{'transform': `scale(${this.scale})`, transformOrigin: 'left'}}>
                        { this.props.pieces.map((p: Piece, i: number) => {
                            return <StackableImage
                                        image={images[p.src]}
                                        clickCallback={()=>this.props.OpenModal(p)}
                                        x={p.x + (p.whichMum === "manal" ? this.manalLeft : this.amaniLeft)}
                                        y={p.y + (p.whichMum === "manal" ? this.manalTop : this.amaniTop)}
                                        shuffledX={p.shuffledX}
                                        shuffledY={p.shuffledY}
                                        key={p.src}
                                        active={this.props.pieceIndex === i && p.video !== undefined}
                                        clickable={
                                            !this.props.modalOpen &&
                                            (this.props.pieceIndex === i || this.props.pieceIndex >= this.props.pieces.length) &&
                                            p.video !==undefined
                                        }
                                        visited={p.visited || false}
                                        hoverText={!!p.title ? `Click to watch ${p.title}`: null}
                                    />;
                        }) }
                    </div>
                </div>
                {_stickFigures}
                {_familyPhotos}
                {
                    this.props.modalOpen ?
                        <Modal OnModalClosed={this.OnModalClosed} ref={this.modalRef}/>
                    :
                    ''
                }
			</>
		)

	}

}
const mapStateToProps = (state) => ({
        pieces: state.videoView.pieces,
        pieceIndex: state.videoView.pieceIndex,
        modalOpen: state.videoView.modalOpen,
        doneTheLastVideo: state.videoView.doneTheLastVideo,
        doneTheLastVideoText: state.videoView.doneTheLastVideoText,
        showFamilyPhotos: state.videoView.showFamilyPhotos,
        enableFamilyPhotos: state.videoView.enableFamilyPhotos,
        showStickFigures: state.videoView.showStickFigures,
    });

const mapDispatchToProps = (dispatch, ownProps) => ({
    OpenModal: (a) => dispatch({type: 'OPEN_MODAL', piece: a}),
    GoToNextPiece: (a) => dispatch({type: 'GO_TO_NEXT_VIDEO'}),
    ShowFamilyPhotos: (a) => dispatch({type: 'SHOW_FAMILY_PHOTOS'}),
    HideFamilyPhotos: (a) => dispatch({type: 'HIDE_FAMILY_PHOTOS'}),
    EnableFamilyPhotos: (a) => dispatch({type: 'ENABLE_FAMILY_PHOTOS'}),
    DisableFamilyPhotos: (a) => dispatch({type: 'DISABLE_FAMILY_PHOTOS'}),
    SetAllPiecesToVisited: (a) => dispatch({type: 'SET_ALL_PIECES_TO_VISITED'}),
    SetCurrentPieceToVisited: (a) => dispatch({type: 'SET_CURRENT_PIECE_TO_VISITED'}),
    ShowMenu: (a) => dispatch({type: 'SHOW_MENU'}),
    CloseModal: (a) => dispatch({type: 'CLOSE_MODAL'})
});

decorate(StackView, {
    screenWidth: observable,
    screenHeight: observable,
    scale: computed,
    height: computed,
    isMobile: computed,
    amaniLeft: computed,
    amaniTop: computed,
    manalLeft: computed,
    manalTop: computed,
})

export default connect(mapStateToProps, mapDispatchToProps)(observer(StackView));

import styled from 'styled-components';
import sketchDrawing from '../../images/sketch_drawing.svg';
import paper_background from '../../images/paper_background.jpg';

const Component = styled.div`
margin: 0 0 55px;
position: relative;
z-index: -1;

header h2 {
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 48px;
  font-family: Montserrat;
  color: white;

  margin-top: 37px;
  margin-bottom: 39px;
}

.visible-xs {
  display: none;
  @media (max-width: 767px) {
    display: inherit;
  }
}

// All the content on the white page
.content-container {
  background-image: url(${paper_background});
  border-radius: 8px;

  width: 768px;
  margin: auto;

  padding-top: 56px;
}

// Separates the credits from the logos at the bottom
.credits-container {
  padding-bottom: 60px;
}

.credits {

  display: grid;
  grid-template-columns: 1.6fr 1fr;

  // IE
  display: -ms-grid;
  -ms-grid-columns: 1.6fr 1fr;

  // Spacing between each individual credit
  padding-bottom: 19px;

  padding-left: 159px;
  padding-right: 100px;
}

.credit-role-container {
  display: inline-flex;
  flex-direction: row;
  height: 14px;
}

.credit-role {
  font-weight: lighter;
  font-size: 14px;

  .and {
    text-transform: lowercase;
  }
}

.credit-dotted-line {
  border-bottom: 1px dashed #F95757;
  flex-grow: 1;
  margin-left: 10px;
}

.credit-names {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: normal;

  // Used to fix the font's starting height to line with the dotted lines
  position: relative;
  bottom: 1px;
  
  -ms-grid-column: 2;
  padding-left: 10px;

  .and {
    text-transform: lowercase;
  }
}

.credits-title {
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-size: 48px;
  color: white;
  @media(max-width: 767px) {
      font-size: 24px;
  
      margin-top: 64px;
      margin-bottom: 51px;
  
      color: rgba(0, 0, 0, 0.87);
    }
}

.music {
  text-transform: none;
  margin-bottom: 24px;
}

.logo {
  h2 {
    font-size: 1rem;
    font-weight: lighter;
    text-align: center;
    margin: 0;
    margin-bottom: 8px;
    line-height: 21px;
  }

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

// Mobile
@media(max-width: 767px) {
  .credits-title {
    font-size: 24px;

    margin-top: 64px;
    margin-bottom: 51px;

    color: rgba(0, 0, 0, 0.87);
  }

  // All the content on the white page
  .content-container {
    background-image: none;
    width: 100%;
    margin: auto;

    padding-top: 0px;
  }

  .credit-role-container {
    display: inline-block;
    text-align: center;
  }

  .credit-role {
    display: inline-block;
    text-align: center;

    font-weight: lighter;
    font-size: 14px;

    border-bottom: 1px solid red;

    .and {
      text-transform: lowercase;
    }
  }

  .credit-dotted-line {
    border-bottom: none;
    display: none;
  }

  .credits {
    display: block;
    text-align: center;

    // Spacing between each individual credit
    padding-bottom: 19px;

    padding-left: 0px;
    padding-right: 0px;
  }

  .credit-names {

    margin-top: 5px;

    text-align: center;
    text-transform: uppercase;
    font-size: 16px;

    .and {
      text-transform: lowercase;
    }
  }
`;
export default Component;
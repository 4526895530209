/*
 * src/store.js
 * With initialState
*/
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';

export const history = createBrowserHistory()

export default function configureStore(initialState={}) {
 // https://github.com/zalmoxisus/redux-devtools-extension#12-advanced-store-setup
 const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
 return createStore(
   rootReducer(history),
   initialState,
   composeEnhancers(
    applyMiddleware(
      routerMiddleware(history),
      thunk,
    )
   ),
 );
}
import styled from 'styled-components';
const Component = styled.div`

  display: flex;
  justify-content: center;
  flex-direction: column; 

  padding-top: 20vh;
  width: 60%;
  margin: auto;
  text-align: left;
  @media (orientation: portrait), (max-width: 767px) {
      padding-top: 64px;
      width: 90%;
  }

  a{
    font-weight: bold;
  }

  .about-title {
    display: block;
    
    margin-bottom: 21px;
    text-align: center;
    text-transform: uppercase;
    
    font-family: Montserrat, sans-serif;
    font-weight: 800;
    font-size: 48px;
    color: rgba(0, 0, 0, 0.87);
    
    @media(max-width: 767px) {
      font-size: 24px;
      margin-bottom: 16px;
    }
  }

  .about-content{
    display: flex;
    justify-content: space-between;
    @media (orientation: portrait), (max-width: 767px) {
      display: block;
      height: 0;
    }
  }

  .about-logo{

      display: block;
      justify-self: end;
      flex: 1;
      margin-bottom: 20px;
      margin-right: 20px;

    @media (orientation: portrait), (max-width: 767px) {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    img {
      max-width: 100%;
    }
  }
  
  .about-text {
    flex: 1;
    display: block;
    line-height: 27px;
    font-weight: lighter;
        
    @media (orientation: portrait), (max-width: 767px) {
      padding: 0 20px;
      max-width: 93%;
      margin: 20px auto auto;
      
      a{
        -ms-hyphens: auto;
        word-break: break-all;
      } 
    }
  }

  .headline {
    font-weight: normal;
    font-size: 18px;
  }
`;
export default Component;

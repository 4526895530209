import styled from 'styled-components';

import close from '../../images/icons/menu-close-default.svg';


const Component = styled.div`
{
  background: rgba(255,255,255,0.5);
  z-index: 1000;
  
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  
  .video-load {
    color: white;
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0px auto;
    position: absolute;
    left: 45%;
    top: 45%;
  }

  .video-box{
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    @media (min-width: 768px) {
      // padding: 5%;
    }
    & > div {
      width: 80%;
    }
    iframe {
      // box-shadow: 10px 10px 18px 0px rgba(0,0,0,0.75);
      position: relative;
      z-index: 1200;
      pointer-events: all;
    }
  }
  
  .close-button {
    position: fixed;
    z-index: 2000;
    width: 36px;
    height: 36px;
    background-position-x: -5px;
    background-position-y: -5px;
    top: 15px;
    right: 15px;
    background-image: url(${close});
    background-repeat: no-repeat;

    @media(max-width: 1050px) {
      display: none;
    }
  }
}
`;
export default Component;
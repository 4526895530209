import React, { Component } from 'react'
import { connect } from 'react-redux';

import classNames from 'classnames';
import CreditsViewStyle from './CreditsView.style'
import CreditsData from './CreditsData';

import bellFund from '../../images/logos/bf_logo.svg';
import stitchMedia from '../../images/logos/stitch_logo.svg';
import morningBird from '../../images/logos/MorningBird_logo_forweb-HR4.png';

import {skipIntroPlayVideos} from '../../redux/actions/videoView.action';

class CreditsView extends Component {

	componentDidMount() {
		// handle pre-scrolled pages on mobile
		window.scrollTo(0,0);
			
		if(this.props.introViewState !== 3) {
			// set the intro state so that we can see the nav menu and skip load
			this.props.setIntroState();
		}
	}

	nameItem(credit, name, index) {
		const classes = classNames("credits-name", credit.type)
		return (
			<div className={classes} key={`name-${index}`}>{name}</div>
		)
	}

	musicItem(credit, item, index) {
		const classes = classNames("credits-name", credit.type)
		return (
			<div className={classes} key={`name-${index}`}>
				"{item.title}" <br/>
				from the album {item.album}
			</div>
		)
	}

	creditLine(item, index) {
		return (
			<div className="credits" key={`credit-${index}`}>
					<div className="credit-role-container">
							<div className="credit-role">{item.role}</div>
							<div className="credit-dotted-line"></div>
					</div>
					<div className="credit-names">
						{item.names.map((n, i) => item.type&&item.type == 'music' ? 
							this.musicItem(item, n, i) :
							this.nameItem(item, n, i)
							)}
					</div>
			</div>
		)
	}

	render() {
		return (
			<CreditsViewStyle>
				<header>
					<h2 className="credits-title">Credits</h2>
				</header>
				<div className="content-container">
					<div className="credits-container">
						{
							CreditsData.credits.map((c, i) => this.creditLine(c, i))
						}
					</div>
					<div className="logo">
							<h2 className="credits-heading">Produced with financial <br />support provided by</h2>
							<a href="https://bellfund.ca/"><img src={bellFund} alt="Bell Fund" title="Bell Fund"/></a>
							<br />
							<br />
					</div>
					<div className="logo">
							<h2 className="credits-heading">Produced by</h2>
							<a href="http://www.morningbirdpictures.com"><img src={morningBird} alt="Morning Bird Pictures" title="Morning Bird Pictures" width={158} height={59} /></a>
							<br />
							<br />
					</div>
					<div className="logo">
							<h2 className="credits-heading">Interactive website developed <br />in collaboration with</h2>
							<a href="https://stitch.media/"><img src={stitchMedia} alt="Stitch Media" title="Stitch Media"/></a>
							<br />
							<br />
					</div>
				</div>
			</CreditsViewStyle>
		)

	}

}
const mapStateToProps = (state) => ({
	introViewState: state.videoView.introViewState,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	setIntroState: a => dispatch(skipIntroPlayVideos()), // on this, show completed puzzles
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditsView);